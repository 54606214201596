<template>
    <div class="w-full h-full flex flex-col cursor-grab" style="background-color: #F9FAFB"
         @wheel="handleWheel" id="whiteBoard"
    >
        <list-header id="header" @saveImg="saveImg" :enable-save-img="true"/>
        <div
                class="w-full h-full relative overflow-visible px-5" :style="{
            'transform-origin':`${originX}px ${originY}px`,
            'transform':`translate(${x}px, ${y}px) scale(${scale})`}" >
            <div class="absolute z-10 w-full"
                 :ref="'nodeRef'+ node.id"  v-for="(node) in chatList" :key="node.id" :style="{left:node.x + 'px', top:node.y + 'px'}">
                <slot :node="node"/>
            </div>
            <chat-link v-for="(link) in linkList" :link="link" :key="link.id"/>
        </div>
    </div>
</template>

<script>
import ListHeader from "@/views/chat/component/ListHeader.vue";
import {ChatTreeStyle} from "@/utils/common";
import {nanoid} from "nanoid";
import {normalizeScale, normalizeWheel} from "@/utils/tools";
import html2canvas from "html2canvas";
import Hammer from "hammerjs";
import ChatLink from "@/views/chat/component/ChatLink.vue";
import {saveChat} from "@/api/chat";
import ToastManager from "@/utils/ToastManager";

export default {
    name: "ChatTreeVerticalTemplate",
    components: {ChatLink, ListHeader},
    data() {
        return {
            last: {x: null, y: null},
            isDrag: false,
            scale: 1.0,
            scales:[1,2,4,8,16,32,64,100,200,400],
            x: 5,
            y: 20,
            originX:0,
            originY:0,
            maxWidth:500,
            maxHeight:500,
            chatList:[],
            linkList:[],
        }
    },
    mounted() {
        this.$nextTick(() => {
            let manager = new Hammer(this.$el);

            //拖动画板
            manager.get('pan').set({direction: Hammer.DIRECTION_ALL});
            let last = {x: null, y: null}
            manager.on("panmove", (event) => {
                if (last.x !== null && last.y !== null) {
                    this.x += (event.center.x - last.x);
                    this.y += (event.center.y - last.y);
                    last = {
                        x: event.center.x,
                        y: event.center.y,
                    }
                }
                last = {
                    x: event.center.x,
                    y: event.center.y,
                }
            });
            manager.on("panstart", (event) => {
                last = {
                    x: event.center.x,
                    y: event.center.y,
                }

                this.last = last;
            })

            let lastScale = this.scale


            //手机端缩放
            manager.get('pinch').set({ enable: true });
            manager.on("pinch pinchmove", (ev)=> {
                // 根据捏合手势的scale属性调整缩放比例
                this.scale = normalizeScale(lastScale * ev.scale);
            });

            manager.on('pinchend',()=>{
                lastScale = this.scale;
            })

            this.manager = manager;
        })


    },
    beforeDestroy() {
        this.manager.destroy();
    },
    methods: {
        saveImg() {
            const {height} = this.getBounds(this.chatList[0].id);
            this.x = 100;
            this.y =  (this.maxHeight - height)/2
            this.$nextTick(()=>{
                html2canvas(document.getElementById('whiteBoard'),
                    {
                        width:this.maxWidth,
                        height:this.maxHeight,
                        x:0,
                        y:0,
                        async: true,
                        backgroundColor: '#F9FAFB',
                        scale:2,
                        logging: true,
                        useCORS: true,
                        allowTaint: true,
                        ignoreElements: (element) => {
                            return element.id === 'header';
                        }
                    })
                    .then(canvas => {
                        let img = new Image();
                        img.crossOrigin = '*';
                        img.src = canvas.toDataURL('image/png');// toDataURL :图片格式转成 base64
                        //document.getElementById('capture').appendChild(img);
                        //如果你需要下载截图，可以使用a标签进行下载
                        //img.crossorigin = '*';
                        let a = document.createElement('a');
                        a.href = canvas.toDataURL('image/png');
                        a.download = '' + nanoid();
                        a.click();
                        this.$message.success('保存成功！');
                    });
            })
        },


        resetTree(chatList) {
            this.x = 5;
            this.y = 20;
            this.chatList = [
                ...chatList,
            ];
            this.updatePosition();
        },


        drawLinkLine(id, parentId) {
            if (!parentId) return "";
            let fromBound = this.getBounds(parentId);
            let toBound = this.getBounds(id);
            const fromPoint = {
                x: fromBound.x + fromBound.width / 2,
                y: fromBound.y + fromBound.height,
            }
            const toPoint = {
                x: toBound.x + toBound.width / 2,
                y: toBound.y,
            }

            let left = Math.min(fromPoint.x, toPoint.x);
            let top = Math.min(fromPoint.y, toPoint.y);
            toPoint.x = toPoint.x - left;
            toPoint.y = toPoint.y - top;
            fromPoint.x = fromPoint.x - left;
            fromPoint.y = fromPoint.y - top;

            const toX = toPoint.x;
            const toY = toPoint.y;
            const cy = (fromPoint.y + toY) * 0.5;
            const cpX = fromPoint.x;
            const cpY = cy;
            const cpX2 = toPoint.x;
            const cpY2 = cy;
            return {
                id: nanoid(),
                path: fromPoint.x === toPoint.x ?
                    `M 1 ${fromPoint.y} L 1 ${toY}`
                    : `M ${fromPoint.x} ${fromPoint.y} C ${cpX} ${cpY} ${cpX2} ${cpY2} ${toX} ${toY}`,
                left: left - 1,
                top: top,
                width: Math.max(2, Math.abs(fromPoint.x - toPoint.x)) + 1,
                height: Math.abs(fromPoint.y - toPoint.y),
            }
        },

        // drawLinkLine(id, parentId) {
        //     if (!parentId) return "";
        //     let fromBound = this.getBounds(parentId);
        //     let toBound = this.getBounds(id);
        //     const fromPoint = {
        //         x: fromBound.x + fromBound.width,
        //         y: fromBound.y + fromBound.height/2,
        //     }
        //     const toPoint = {
        //         x: toBound.x,
        //         y: toBound.y + toBound.height / 2,
        //     }
        //
        //     let left = Math.min(fromPoint.x, toPoint.x);
        //     let top = Math.min(fromPoint.y, toPoint.y);
        //     toPoint.x = toPoint.x - left;
        //     toPoint.y = toPoint.y - top;
        //     fromPoint.x = fromPoint.x - left;
        //     fromPoint.y = fromPoint.y - top;
        //
        //     const toX = toPoint.x;
        //     const toY = toPoint.y;
        //     const cx = (fromPoint.x + toX) * 0.5;
        //     const cpX = cx;
        //     const cpY = fromPoint.y;
        //     const cpX2 = cx;
        //     const cpY2 = toPoint.y;
        //     return {
        //         id: nanoid(),
        //         path: fromPoint.y === toPoint.y ?
        //             `M ${fromPoint.x} 1 L ${toX} 1` :
        //             `M ${fromPoint.x} ${fromPoint.y} C ${cpX} ${cpY} ${cpX2} ${cpY2} ${toX} ${toY}`,
        //         left: left - 1,
        //         top: top,
        //         width: Math.max(2, Math.abs(fromPoint.x - toPoint.x)) + 1,
        //         height: Math.max(2,Math.abs(fromPoint.y - toPoint.y)) +1,
        //     }
        // },


        getBounds(id) {
            let node = this.findNodeById(id);

            let height = this.$refs['nodeRef' + id][0].offsetHeight;
            let width = this.$refs['nodeRef' + id][0].offsetWidth;
            let x = node.x;
            let y = node.y;
            return {
                height: height,
                width: width,
                x: x,
                y: y,
            }
        },


        handleWheel(event) {
            let flag = event.ctrlKey || event.metaKey
            let originX = event.clientX;
            let originY = event.clientY;
            event.preventDefault();
            event.stopPropagation();
            event = normalizeWheel(event);

            if (flag) {
                let lastScale = this.scale;
                if (event.y < 0) {
                    this.scale = normalizeScale(this.scale - 0.1);
                } else {
                    this.scale = normalizeScale(this.scale + 0.1);
                }
                if (lastScale !== this.scale) {
                    this.originX = originX - this.x;
                    this.originY = originY - this.y;
                }
            } else {
                this.x += event.x;
                this.y += event.y;
            }


        },


        zoomIn() {

        },

        zoomOut() {

        },



        addNodeManual(node,callback) {
            let parentNode = this.findNodeById(node.parentId);
            if (parentNode) {
                parentNode.children.push(node);
                this.chatList.push(node);
                this.updatePosition(callback);
            }
        },

        clickDivMenu(params) {
            this.$emit("clickDivMenu",params);
        },

        clickMoreMenu(params) {
            this.$emit("clickMoreMenu",params);
        },

        showMobileDrawer(params) {
            this.$emit("showMobileDrawer",params);
        },


        async deleteNode(node) {

            console.log("delete node", node);

            if (!await this.deleteChat(node.nodeId)) {
                ToastManager.showError("删除失败！");
                return;
            }


            let parentNode = this.findNodeById(node.parentId);
            if (!parentNode) return;
            parentNode.children.splice(node.index, 1);
            // parentNode.children.forEach((item, index) => {
            //     item.index = index;
            // })
            this.deleteParentNode(node);
            this.updatePosition();
        },

        goTop(node) {
            let parentId = node.parentId;
            let index = this.findIndexById(node.id);
            let parentNode = this.findNodeById(parentId);
            let [topNode] = parentNode.children.splice(this.chatList[index].index, 1);
            parentNode.children.unshift(topNode);
            // parentNode.children.forEach((item, index) => {
            //     item.index = index;
            // })

            if (parentNode.children.length > 1) {
                topNode.index = parentNode.children[1].index-1;
                this.updateSortKey(topNode);
            }
            this.updatePosition();
        },

        findIndexById(id) {
            let ans;
            this.chatList.forEach((node,index)=>{
                if (node.id === id) {
                    ans = index;
                }
            })
            return ans;
        },


        async updateSortKey(node) {
            console.log(node);
            const res = await saveChat({
                id:node.nodeId,
                sortKey:node.index,
            })
            if (res.code === 0) {
                console.log("update");
            }
        },

        async deleteChat(id) {
            const res = await saveChat({
                id:id,
                status:0,
            })
            if (res.code === 0) {
                return true;
            } else {
                return false;
            }
        },


        findNodeById(id) {
            let ans;
            this.chatList.forEach((node)=>{
                if (node.id === id) {
                    ans = node;
                }
            })
            return ans;
        },


        deleteParentNode(parentNode) {
            const getAllChildren = (node) => {
                let children = [];
                node.children.forEach((item) => {
                    children.push(item);
                    children = children.concat(getAllChildren(item));
                    this.deleteChat(item.nodeId);
                })
                return children;
            }

            let deleteList = getAllChildren(parentNode);
            deleteList.push(parentNode);
            deleteList.forEach((item) => {
                this.chatList.splice(this.chatList.findIndex((node) => node.id === item.id), 1);
            })

        },
        updatePosition(callback) {
            this.$nextTick(() => {
                const position = this.calculateVPosition();
                this.linkList.splice(0);
                this.chatList.forEach((node) => {
                    node.x = position[node.id].x;
                    node.y = position[node.id].y;
                    if (node.parentId) {
                        this.linkList.push(this.drawLinkLine(node.id, node.parentId))
                    }
                })
                if (callback) {
                    callback();
                }
            })
        },

        calculateVPosition() {
            const position = {};
            const spacing = ChatTreeStyle.spacing;

            // 深度优先遍历
            let infos = [...this.chatList];


            //从子节点开始计算所有节点的高度
            infos = infos.sort((a, b) => {
                if (a.stage === b.stage) {
                    return b.index - a.index;
                } else {
                    return b.stage - a.stage;
                }
            });
            let sizes = {};


            const getSize = (idx) => {
                const node = infos[idx];
                const {id} = node;
                const children = node.children;
                const len = children.length;
                const {width} = this.getBounds(id);
                // 父节点的高度边界等于 max（父节点的高度，子节点高度之和)
                if (len === 0) {
                    sizes[id] = {width};
                } else {
                    let th = 0;
                    for (let i = 0; i < len; i++) {
                        const cId = children[i].id;
                        const child_size = sizes[cId];
                        th += child_size.width;
                        if (i < len - 1) th += spacing.y;
                    }
                    th = Math.max(th, width);
                    sizes[id] = {width: th};
                }
                idx++;
                if (idx < infos.length) getSize(idx);
            }
            getSize(0);

            this.maxHeight = sizes[this.chatList[0].id].height + 200;
            const {width} = this.getBounds(this.chatList[0].id);
            this.maxWidth = (this.chatList[this.chatList.length-1].stage+1) * (width + spacing.x) + 200 - spacing.x;

            const getGlobalPosition = (node) => {
                const {x, y, width, height} = this.getBounds(node.id);

                if (!node.parentId) {
                    const {id} = node;
                    position[id] = {x, y};
                }

                const sortedChildren = node.children.sort((a, b) => {
                    return a.index - b.index;
                });

                const parentPos = position[node.id];

                if (sortedChildren.length > 0) {
                    const widths = sizes[node.id].width;
                    let tx = parentPos.x + (width - widths) * 0.5;
                    //let tx = parentPos.x;
                    const ty = parentPos.y + height + spacing.y;
                    for (let i = 0; i < sortedChildren.length; i++) {
                        const childNode = sortedChildren[i];
                        const {id} = childNode;
                        const size = sizes[id];
                        const childLen = childNode.children.length;


                        if (childLen > 0) {
                            const {width: ch} = this.getBounds(childNode.id);
                            position[id] = {x: tx + (size.width - ch) * 0.5, y: ty};
                            //position[id] = {x: tx, y: ty};
                        } else {
                            position[id] = {x: tx, y: ty};
                        }
                        tx += size.width;
                        if (i < sortedChildren.length - 1) {
                            tx += spacing.x;
                        }
                        getGlobalPosition(childNode);
                    }
                }
            }
            getGlobalPosition(this.chatList[0]);
            return position;
        },

        //
        // calculateVPosition() {
        //     const position = {};
        //     const spacing = ChatTreeStyle.spacing;
        //
        //     // 深度优先遍历
        //     let infos = [...this.chatList];
        //
        //
        //     //从子节点开始计算所有节点的高度
        //     infos = infos.sort((a, b) => {
        //         if (a.stage === b.stage) {
        //             return b.index - a.index;
        //         } else {
        //             return b.stage - a.stage;
        //         }
        //     });
        //     let sizes = {};
        //
        //
        //     const getSize = (idx) => {
        //         const node = infos[idx];
        //         const {id} = node;
        //         const children = node.children;
        //         const len = children.length;
        //         const {width} = this.getBounds(id);
        //         // 父节点的高度边界等于 max（父节点的高度，子节点高度之和)
        //         if (len === 0) {
        //             sizes[id] = {width};
        //         } else {
        //             let th = 0;
        //             for (let i = 0; i < len; i++) {
        //                 const cId = children[i].id;
        //                 const child_size = sizes[cId];
        //                 th += child_size.width;
        //                 if (i < len - 1) th += spacing.x;
        //             }
        //             th = Math.max(th, width);
        //             sizes[id] = {width: th};
        //         }
        //         idx++;
        //         if (idx < infos.length) getSize(idx);
        //     }
        //     getSize(0);
        //
        //     const getGlobalPosition = (node) => {
        //         const {x, y, width, height} = this.getBounds(node.id);
        //         //const {x,y,height} = this.getBounds(node.id);
        //         if (!node.parentId) {
        //             const {id} = node;
        //             position[id] = {x, y};
        //         }
        //         const sortedChildren = node.children.sort((a, b) => {
        //             return a.index - b.index;
        //         });
        //
        //         const parentPos = position[node.id];
        //         if (sortedChildren.length > 0) {
        //             const widths = sizes[node.id].width;
        //             let tx = parentPos.x + (width - widths) * 0.5;
        //             //let tx = parentPos.x;
        //             const ty = parentPos.y + height + spacing.y;
        //             for (let i = 0; i < sortedChildren.length; i++) {
        //                 const childNode = sortedChildren[i];
        //                 const {id} = childNode;
        //                 const size = sizes[id];
        //                 const childLen = childNode.children.length;
        //
        //
        //                 if (childLen > 0) {
        //                     const {width: ch} = this.getBounds(childNode.id);
        //                     position[id] = {x: tx + (size.width - ch) * 0.5, y: ty};
        //                     //position[id] = {x: tx, y: ty};
        //                 } else {
        //                     position[id] = {x: tx, y: ty};
        //                 }
        //                 tx += size.width;
        //                 if (i < sortedChildren.length - 1) {
        //                     tx += spacing.x;
        //                 }
        //                 getGlobalPosition(childNode);
        //             }
        //         }
        //     }
        //     getGlobalPosition(this.chatList[0]);
        //     return position;
        // },
    }
}
</script>

<style scoped>
</style>
