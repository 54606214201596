import ToastManager from "@/utils/ToastManager";

/**
 * 手机号校验
 * @param str
 * @returns {boolean}
 */
export function isValidPhone(str,showMsg = true) {
    const reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/;
    const ans = reg.test(str);
    if (!ans && showMsg) ToastManager.showWarning("手机号码格式错误！")
    return ans;
}

/**
 * 验证码校验
 * @param code
 * @returns {boolean}
 */
export function isValidSmsCode(code, showMsg=true) {
    const reg = /[0-9]{6}$/;
    const ans =  reg.test(code);
    if (!ans && showMsg) ToastManager.showWarning("手机号码格式错误！")
    return ans;
}


/**
 * 密码校验
 * @param code
 * @returns {boolean}
 */
export function isValidPassword(password, showMsg=true) {
    // 密码至少包含一个小写字母
    let ans = false;
    let lowerCase = /[a-z]/g;
    // 密码至少包含一个大写字母
    let upperCase = /[A-Z]/g;
    // 密码至少包含一个数字
    let numbers = /[0-9]/g;

    if(password.length < 8) {
        ans = false;
        if (showMsg) ToastManager.showWarning("密码长度要大于等于8!")
    } else if(!password.match(lowerCase)) {
        ans = false;
        if (showMsg) ToastManager.showWarning("密码至少要包含一个小写字母!")
    } else if(!password.match(upperCase)) {
        ans = false;
        if (showMsg) ToastManager.showWarning("密码至少要包含一个大写字母!")
    } else if(!password.match(numbers)) {
        ans = false;
        if (showMsg) ToastManager.showWarning("密码至少要包含一个数字!")
    } else {
        ans = true;
    }


    return ans;
}


/**
 * 获取文本长度
 */
export function getTextLength (value) {
    // 中文、中文标点、全角字符按1长度，英文、英文符号、数字按0.5长度计算
    let cnReg = /([\u4e00-\u9fa5]|[\u3000-\u303F]|[\uFF00-\uFF60])/g
    let mat = value.match(cnReg)
    let length
    if (mat) {
        length = (mat.length + (value.length - mat.length) * 0.5)
        return length
    } else {
        return value.length * 0.5
    }
}

/**
 * 限制输入长度
 * 如10个中文，英文就可以20
 */
export function validateTextLength(value,length=10) {
    return getTextLength(value) <= length;
}
