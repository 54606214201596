<template>
    <el-popover trigger="hover" placement="right" :disabled="true">
        <div class="w-80 h-fit p-3 whitespace-pre-line text-base text-gray-500 flex-1">
            {{ user.figureReq.description }}
        </div>
        <div :class="[isSelect ? 'bg-gray-200 shadow' : 'bg-white cursor-pointer']"
                class="w-full h-20 flex flex-row  items-center mb-2 p-4
                justify-center rounded-xl transition-shadow duration-500"
                @click="select" slot="reference">
            <avatar :user="user.figureReq" />
            <div class="h-16 w-full flex flex-col items-start justify-center ml-2">
                <div class="text-base" :class="[isSelect ? 'text-black' : 'text-black']">{{user.figureReq.name}}</div>
                <div class="inline-block w-full text-ellipsis overflow-hidden text-sm h-5"
                     :class="[isSelect ? 'text-gray-400' : 'text-gray-400']">{{user.figureReq.welcome}}</div>
            </div>
        </div>
    </el-popover>
</template>

<script>
import Avatar from "@/components/avatar.vue";

export default {
    name: "FriendItem",
    components: {Avatar},
    props: {
        user: {

        },
        isLast: {

        }
    },

    computed: {
      isSelect() {
        return this.$route.params.friendId === this.user.id.toString();
      }
    },

    methods: {
        select() {
            this.$emit("select",this.user);
        }
    }
}
</script>

<style scoped>

</style>
