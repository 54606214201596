<template>
    <div class="flex flex-row">
        <div :class="getLabelColors(item)" class="rounded px-2 py-1 mr-2 text-sm text-gray-700" v-for="(item) in itemList" :key="item">{{item}}</div>
    </div>
</template>

<script>
import {getLabelColors} from "@/utils/color";

export default {
    name: "LabelItems",
    methods: {getLabelColors},
    props: {
        label: {

        }
    },
    mounted() {
        this.itemList = this.label.split(':::');
    },
    data() {
        return {
            itemList:[],
        }
    }
}
</script>

<style scoped>

</style>
