<template>
    <div class="relative">
        <select
                v-if="computedType === 'select'"
                :id="id"
                v-model="computedValue"
                :name="name"
                :class="inputElClass"
                @input="updateValue($event.target.value)"
        >
            <option
                    v-for="option in options"
                    :key="option.id ?? option"
                    :value="option"
            >
                {{ option.label ?? option }}
            </option>
        </select>
        <textarea
                v-else-if="computedType === 'textarea'"
                :id="id"
                v-model="computedValue"
                :class="inputElClass"
                :name="name"
                :placeholder="placeholder"
                :required="required"
                @input="updateValue($event.target.value)"
        />
        <input
                v-else
                :id="id"
                ref="inputEl"
                v-model="computedValue"
                :name="name"
                :inputmode="inputmode"
                :autocomplete="autocomplete"
                :required="required"
                :placeholder="placeholder"
                :type="computedType"
                :class="inputElClass"
                :maxlength="type === 'sms' ? 6 : null"
                @input="updateValue($event.target.value)"
        />
        <form-control-icon v-if="icon" :icon="icon" :h="controlIconH"/>
        <sms-code v-if="type === 'sms'" :phone="phone" @setSmsToken="setSmsToken"/>
    </div>
</template>

<script>
import FormControlIcon from "@/components/form/FormControlIcon.vue";
import SmsCode from "@/components/form/SmsCode.vue";

export default {
    name: "FormInput",
    components: {SmsCode, FormControlIcon},
    props: {
        phone: {},
        name: {
            type: String,
            default: null,
        },
        id: {
            type: String,
            default: null,
        },
        autocomplete: {
            type: String,
            default: null,
        },
        placeholder: {
            type: String,
            default: null,
        },
        inputmode: {
            default: null,
        },
        icon: {
            type: String,
            default: null,
        },
        options: {
            default: null,
        },
        type: {
            type: String,
            default: "text",
        },
        value: {type: String},
        required: Boolean,
        borderless: Boolean,
        transparent: Boolean,
        ctrlKFocus: Boolean,
    },

    mounted() {},
    data() {
        return {}
    },
    computed: {
        computedValue: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        },
        controlIconH() {
            return this.type === "textarea" ? 'h-full' : 'h-12'
        },
        computedType() {
            return this.options ? "select" : this.type;
        },
        inputElClass() {
            const base = [
                "px-3 py-2 max-w-full focus:ring focus:outline-none border-gray-700 rounded w-full",
                "dark:placeholder-gray-400",
                this.computedType.value === "textarea" ? "h-24" : "h-12",
                this.borderless ? "border-0" : "border",
                this.transparent ? "bg-transparent" : "bg-white",
            ];

            if (this.icon) {
                base.push("pl-10");
            }

            return base;
        }
    },
    methods: {
        updateValue(value) {
            this.computedValue = value;
        },
        setSmsToken(value) {
            this.$emit("setSmsToken",value);
        }
    }
}
</script>

<style scoped>

</style>
