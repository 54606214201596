<template>
    <div class="mb-6 last:mb-0">
        <label v-if="label" class="block font-bold mb-2">
            {{ label }}
        </label>
        <div :class="wrapperClass">
            <slot />
        </div>
        <div v-if="help" class="text-xs text-gray-500 mt-1">
            {{ help }}
        </div>
    </div>
</template>

<script>
export default {
    name: "FormField",
    props: {
        label: {

        },
        help: {

        },
    },
    computed: {
        wrapperClass() {
            const base = [];
            // const slotsLength = slots.default?.().length ?? 0;
            //
            // if (slotsLength > 1) {
            //     base.push("grid grid-cols-1 gap-3");
            // }
            //
            // if (slotsLength === 2) {
            //     base.push("md:grid-cols-2");
            // }

            return base;
        }
    }
}
</script>

<style scoped>

</style>
