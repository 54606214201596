<template>
    <base-dialog ref="dialogRef">
        <div slot="body"
             class="w-full lg:w-5/12  h-full sm:h-4/6 lg:rounded-xl bg-white shadow-2xl overflow-hidden p-5 flex flex-col">
            <div class="flex flex-row  w-full h-fit bg-white items-center py-5">
                <div class="text-2xl text-black font-bold">添加数据</div>
                <div class="flex-1"/>
                <close-button @click.native="closeDialog"/>
            </div>


            <textarea v-if="currentType === 'content'"
                      class="w-full h-full
            p-1.5 pl-3 pr-10 resize-none border rounded-lg mb-5
            outline-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-40"
                      ref="textareaDom"
                      v-model="content"
                      placeholder="请输入或粘贴你需要添加的文本数据"
                      @focus="handleFocus"
            ></textarea>


            <div class="w-full h-full flex flex-col items-center justify-center mb-5"  v-if="currentType === 'file'">
                <div class="w-full h-full" v-show="file === null">
                    <input type="file" ref="fileInput" v-on:change="handleFile" style="display: none">
                    <div
                        class="border border-dashed rounded-xl cursor-pointer w-full h-full flex flex-col items-center justify-center"
                        :class="[isOver ? 'bg-gray-200 border-blue-500' : 'border-gray-500 bg-gray-50']"

                        v-on:dragover.prevent="dragover" v-on:dragleave="dragleave" v-on:drop.prevent="drop" v-on:click="selectFile">
                        <span class="text-xl">拖拽文件至此或点击选择文件</span>
                        <span class="text-base mt-2">支持PDF、TXT、Markdown文件，大小不超过2M</span>
                    </div>
                </div>

                <div class="w-full h-fit flex px-3 py-2 flex-row border border-solid border-gray-100 rounded" v-show="file !== null">
                    <div class="text-base" v-if="file">{{file.name}}</div>
                    <div class="flex-1"/>
                    <base-button class="ml-5 h-fit" :icon="mdiDeleteOutline" color="danger" :icon-size="20"
                                 @click.native.stop="file = null"/>
                </div>
            </div>


            <div class="w-full h-full flex flex-col items-center justify-center mb-5"  v-if="currentType === 'url'">
                <div class="w-full h-full">
                    <div class="w-full h-fit mt-3 flex flex-row items-center" v-for="(item,index) in urlList" :key="item.key">
                        <el-input  placeholder="请输入网址"
                                  v-model="item.url"  clearable/>
                        <base-button class="ml-5 h-fit" :icon="mdiDeleteOutline" color="danger" :icon-size="20"
                                     @click.native.stop="deleteUrl(index)"
                                     v-if="urlList.length > 1"/>
                    </div>
                    <div class="w-full h-fit flex flex-row-reverse mt-3">
                        <base-button
                            v-if="urlList.length < 5"
                            :icon="mdiPlus" :icon-size="20" label="添加" type="submit" color="info" :outline="true" @click.native.stop="addUrl"/>
                    </div>
                </div>
            </div>


            <div class="flex-1"/>
            <div class="flex flex-row w-full h-10">
                <div class="flex-1"/>
                <base-button type="submit" label="确定" color="info" class="text-base" :disabled="disabled"
                             @click.native.stop="submit"/>
                <base-button :outline="true" type="submit" label="取消" color="info"
                             @click.native.stop="closeDialog"
                             class="text-base ml-5"/>
            </div>
        </div>
    </base-dialog>
</template>

<script>
import BaseDialog from "@/components/dialog/BaseDialog.vue";
import CloseButton from "@/components/button/CloseButton.vue";
import BaseButton from "@/components/button/BaseButton.vue";
import {nanoid} from "nanoid";
import {mdiDeleteOutline,mdiPlus} from "@mdi/js";
import ToastManager from "@/utils/ToastManager";
import {uploadByFile, uploadByUrls, uploadContent} from "@/api/knowledge";
import Broadcaster from "@/utils/Broadcaster";

export default {
    name: "AddDocumentDialog",
    components: { BaseButton, CloseButton, BaseDialog},
    mounted() {

    },
    computed: {
        disabled() {
            return this.content.length === 0 && this.urlList.length === 0 && this.file === null
        }
    },
    data() {
        return {
            currentType: null,
            content: "",
            urlList: [
                {
                    url:"",
                    key:nanoid(),
                }
            ],
            file: null,
            isOver:false,
            mdiDeleteOutline:mdiDeleteOutline,
            mdiPlus:mdiPlus,
        }
    },
    methods: {
        closeDialog() {
            this.$refs.dialogRef.closeDialog()
        },
        selectFile() {
            this.$refs.fileInput.click();
        },
        handleFile(event) {
            const file = event.target.files[0];
            this.uploadFile(file);
        },
        dragover(event) {
            event.dataTransfer.dropEffect = 'move';
            this.isOver = true;
        },
        dragleave() {
            this.isOver = false;
        },
        drop(event) {
            const file = event.dataTransfer.files[0];
            this.isOver = false;
            this.uploadFile(file);

        },
        uploadFile(file) {
            if (!file) return;
            let fileName = file.name.split('.')
            if (fileName[fileName.length-1] !== 'pdf' && fileName[fileName.length-1] !== 'txt' && fileName[fileName.length-1] !== 'md') {
                ToastManager.showWarning("仅支持PDF、TXT、Markdown文件格式！")
                this.file = null;
                return;
            }
            let size = file.size/(1024*1024)
            if (size > 2) {
                ToastManager.showWarning("当前仅支持小于2M的文件")
                this.file = null;
                return;
            }

            this.file = file;
        },
        showDialog(type) {
            this.currentType = type;
            this.content = "";
            this.urlList = [
                {
                    url:"",
                    key:nanoid(),
                }
            ];
            this.file = null;
            this.$refs.dialogRef.showDialog()
        },
        addUrl() {
          this.urlList.push({
              url: "",
              key: nanoid(),
          })
        },
        deleteUrl(index) {
          this.urlList.splice(index,1);
        },
        submit() {
            if (this.currentType === "content") {
                this.uploadByContent();
            } else if (this.currentType === "url") {
                this.uploadByUrls();
            } else if (this.currentType === "file") {
                this.uploadByFile();
            }
        },

        async uploadByUrls() {
            let urls = []
            this.urlList.forEach((item)=>{
                if (item.url.length > 0) {
                    urls.push(item.url)
                }
            })
            if (urls.length === 0) {
                ToastManager.showWarning("上传的网址不能为空！")
                return;
            }

            const res = await uploadByUrls({
                friendId:this.$route.params.friendId,
                urls : urls
            })
            if (res.code === 0) {
                Broadcaster.$emit("updateUploadedList");
                ToastManager.showSuccess2("数据已上传至服务器并在后台处理中。处理完成后，您便可以提问相关问题。");
                this.closeDialog();
            } else {
                ToastManager.showError(res.msg);
            }

        },

        async uploadByFile() {
            if (this.file === null) {
                ToastManager.showWarning("上传的文件不能为空！")
                return;
            }

            let fileName = this.file.name.split('.')
            if (fileName[fileName.length-1] === 'pdf' || fileName[fileName.length-1] === 'txt' || fileName[fileName.length-1] === 'md') {
                const res = await uploadByFile({
                    friendId:this.$route.params.friendId,
                    file : this.file
                })
                if (res.code === 0) {
                    Broadcaster.$emit("updateUploadedList");
                    ToastManager.showSuccess2("数据已上传至服务器并在后台处理中。处理完成后，您便可以提问相关问题。");
                    this.closeDialog();
                } else {
                    ToastManager.showError(res.msg);
                }
            } else {
                ToastManager.showWarning("仅支持PDF、TXT、Markdown文件格式！")
                this.file = null;
            }


        },

        async uploadByContent() {
            if (this.content.length === 0) {
                ToastManager.showWarning("上传的内容不能为空！")
                return;
            }
            const res = await uploadContent({
                friendId:this.$route.params.friendId,
                content : this.content
            })
            if (res.code === 0) {
                Broadcaster.$emit("updateUploadedList");
                ToastManager.showSuccess2("数据已上传至服务器并在后台处理中。处理完成后，您便可以提问相关问题。");
                this.closeDialog();
            } else {
                ToastManager.showError(res.msg);
            }
        },



        handleFocus() {
            this.$nextTick(() => {
                window.setTimeout(() => {
                    const element = this.$refs.textareaDom;
                    const elementRect = element.getBoundingClientRect();
                    const absoluteElementTop = elementRect.top + window.pageYOffset;
                    const middlePosition = absoluteElementTop - (window.innerHeight / 2);
                    window.scrollTo({top: middlePosition, behavior: 'smooth'});
                }, 500);
            })
        },
    }
}
</script>

<style scoped>

</style>
