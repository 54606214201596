<template>
    <base-dialog ref="deleteDialogRef">
        <div slot="body" class="w-full lg:w-4/12 flex flex-col lg:h-fit lg:rounded-xl bg-white shadow-2xl overflow-y-auto p-5">
            <div class="flex flex-row  w-full h-fit bg-white items-center py-5">
                <div class="text-2xl text-black font-bold">{{title}}</div>
            </div>
            <div class="text-base text-black h-fit w-full py-5">
                {{tips}}
            </div>
            <div class="flex flex-row w-full h-10">
                <div class="flex-1"/>
                <base-button type="submit" label="删除" color="danger" class="text-base"
                             @click.native.stop="submit"/>
                <base-button :outline="true" type="submit" label="取消" color="info"
                             @click.native.stop="closeDialog"
                             class="text-base ml-5"/>
            </div>
        </div>
    </base-dialog>
</template>

<script>
import BaseDialog from "@/components/dialog/BaseDialog.vue";
import BaseButton from "@/components/button/BaseButton.vue";

export default {
    name: "DeleteDialog",
    components: {BaseButton, BaseDialog},
    props: {
      title:{
          default() {
              return "温馨提示"
          }
      },
      tips: {

      }
    },
    data() {
        return {

        }
    },
    methods: {
        submit() {
            this.$emit("submit")
        },
        showDialog() {
            this.$refs.deleteDialogRef.showDialog()
        },
        closeDialog() {
            this.$refs.deleteDialogRef.closeDialog();
        }
    }
}
</script>

<style scoped>

</style>
