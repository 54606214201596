import Vue from 'vue'
import App from './App.vue'
import './style/index.css'
import './permission'
import router from "@/router";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import clickoutside from 'element-ui/src/utils/clickoutside';

Vue.directive('clickoutside', clickoutside);
Vue.use(ElementUI)
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
