<template>
    <div>
        <button class="rounded-md cursor-pointer" :disabled="disabled">
            <BaseIcon
                    :class="
          disabled
            ? 'text-slate-300'
            : 'text-blue-600'
        "
                    size="24" :icon="mdiSend"
            />
        </button>
    </div>
</template>

<script>
import BaseIcon from "@/components/button/BaseIcon.vue";
import {mdiSend} from "@mdi/js"
export default {
    name: "ChatSendButton",
    components: {BaseIcon},
    props: {
        disabled: {

        }
    },
    data() {
      return {
          mdiSend:mdiSend,
      }
    }
}
</script>

<style scoped>

</style>
