<template>
    <base-icon
            :icon="icon"
            :icon-size="20"
            :class="[h]"
            class="absolute top-0 w-10 left-0  pointer-events-none text-gray-500"
    />
</template>

<script>
import BaseIcon from "@/components/button/BaseIcon.vue";

export default {
    name: "FormControlIcon",
    components: {BaseIcon},
    props: {
        icon: {

        },
        h: {

        }
    }
}
</script>

<style scoped>

</style>
