<template>
    <div class="flex flex-col w-full p-10 bg-white rounded-2xl ">
        <div class="text-black font-bold text-2xl mb-10">{{title}}</div>
        <slot/>
    </div>
</template>

<script>
export default {
    name: "AccountItemCard",
    props: ["title"]
}
</script>

<style scoped>

</style>
