<template>
    <div class="flex flex-col w-11/12 h-fit" v-clickoutside="unselect" @pointerdown="pointerdown">
        <div :class="[isSelect ? 'border-blue-700' : 'border-white']"
             class="w-full h-fit rounded-xl shadow bg-white flex flex-col p-5 cursor-pointer border border-solid "
             @click="select">
            <div class="flex flex-row">
                <div class="text-base font-bold text-black  mr-2 mt-2 leading-8">Q:</div>
                <chat-node-input :key="node.id"
                                 :content="question" @inputValue="inputQuestion" @blur="updateQuestion" :placeholder="'请输入你的问题'"/>
            </div>
            <div class="flex flex-row mt-3" v-if="isShowAnswer">
                <div class="text-base font-bold text-black leading-8 mt-2 mr-2">A:</div>
                <chat-node-input :key="node.id" :content="answer" @inputValue="inputAnswer" @blur="updateAnswer" :placeholder="'请输入答案'"/>
            </div>
            <div class="flex flex-row mt-5" v-if="isSelect">
                <div class="flex-1"></div>
                <base-button label="自己答" :disabled="isShowAnswer" :outline="true" color="info" class="text-xs mr-2" @click.native="showAnswer"/>
                <base-button label="询问AI" color="info" class="text-xs" @click.native="askAI"/>
            </div>
        </div>

        <div class="absolute -top-12 left-0 w-full h-10  flex flex-row items-center justify-center"
             v-if="isSelect && node.stage !== 0">
            <div class="flex flex-row w-fit bg-white p-1 rounded shadow">
                <base-button tips="删除" :icon-size="20" :icon="mdiDelete" @click.native.stop="deleteNode"/>
                <base-button tips="置顶" :icon-size="20" :icon="mdiArrowUpBold" @click.native.stop="goTop" v-if="false"/>
            </div>
        </div>

        <div class="absolute -bottom-10 h-10 w-full flex flex-col items-center justify-center">
            <base-button tips="添加下一级对话" class="shadow" :icon-size="20" :icon="mdiPlus"
                         v-show="isSelect" @click.native.stop="clickDivMenu"/>
        </div>


        <div class="absolute right-10  w-10 h-full flex-row items-center justify-center flex " v-show="isSelect && node.stage > 0">
            <base-button tips="添加同级对话"
                         @click.native.stop="clickMoreMenu"
                         style="transform: rotate(180deg) scaleX(-1);"
                         class="shadow" :icon-size="20" :icon="mdiSourceBranch"></base-button>
        </div>
    </div>
</template>

<script>
import ChatNodeInput from "@/views/chat/component/ChatNodeInput.vue";
import BaseButton from "@/components/button/BaseButton.vue";
import {mdiArrowUpBold, mdiDelete, mdiPlus, mdiSourceBranch} from "@mdi/js";

export default {
    name: "ChatDeepNode",
    components: {BaseButton, ChatNodeInput},
    props: {
        node: {}
    },
    data() {
        return {
            mdiPlus: mdiPlus,
            mdiDelete: mdiDelete,
            mdiSourceBranch: mdiSourceBranch,
            mdiArrowUpBold: mdiArrowUpBold,
            isSelect: false,
            isShowDrawer: false,
            isShowPopover: false,
            question: "",
            answer: "",
            isShowAnswer:false,
        }
    },
    mounted() {
        this.init();
    },
    watch: {
        node() {
            this.init();
        }
    },
    methods: {
        pointerdown(event) {
            event.stopPropagation();
        },
        init() {
            this.question = this.node.question;
            this.answer = this.node.answer;
            if (this.answer && this.answer.length > 0) {
                this.isShowAnswer = true;
            }
        },
        select() {
            this.isSelect = true
            this.$emit("updatePosition");
        },
        unselect() {
            this.isSelect = false;
            this.$emit("updatePosition");
        },
        inputQuestion(value) {
            this.question = value;
        },
        inputAnswer(value) {
            this.answer = value;
        },

        updateQuestion() {
            this.$emit("updateQuestion",this);
        },

        updateAnswer() {
            this.$emit("updateAnswer",this)
        },


        clickMoreMenu() {
            this.unselect();
            this.$emit("clickMoreMenu", {node: this.node});
        },
        showAnswer() {
            this.isShowAnswer = true;
        },
        askAI() {
            this.$emit("askAI", {that: this});
        },
        clickDivMenu() {
            this.unselect();
            this.$emit("clickDivMenu", {node: this.node});
        },
        deleteNode() {
            this.$emit("deleteNode", {node: this.node});
        },
        goTop() {
            this.$emit("goTop", {node: this.node});
        },
    }
}
</script>

<style scoped>

</style>
