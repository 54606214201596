<template>
    <component :is="currentComponent">
    </component>
</template>

<script>
import OkrCopilotTree from "@/views/chat/component/OkrCopilotTree.vue";
import ScamperTree from "@/views/chat/component/ScamperTree.vue";
import ChatTreeTemplate from "@/views/chat/component/ChatTreeTemplate.vue";
import ChatDeepTree from "@/views/chat/component/ChatDeepTree.vue";

export default {
    name: "ChatTree",
    components: {
        OkrCopilotTree,
        ScamperTree,
        ChatDeepTree,
        ChatTreeTemplate
    },
    props: {
        currentComponent: {

        },
    },
    data() {
        return {

        }
    },
    mounted() {

    },

}
</script>

<style scoped>

</style>
