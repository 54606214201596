<template>
    <div :class="[ intervalId ? 'text-blue-500 pointer-events-none' : 'text-gray-500 cursor-pointer']"
            class="text-xs absolute top-0 w-20 h-12  pr-4 right-0 z-10
          flex flex-row items-center justify-end"
         @click="startCount">{{verifyBtnTxt}}</div>
</template>

<script>
import {isValidPhone} from "@/utils/validate";
import {getSmsCode} from "@/api/user";
import ToastManager from "@/utils/ToastManager";

export default {
    name: "SmsCode",
    props: {
      phone: {

      }
    },
    data() {
        return {
            verifyBtnTxt:"获取验证码",
            smsCode: "",
            countDown: 60,
            intervalId:null,
        }
    },

    methods: {
        async getSmsCode() {
            const res = await getSmsCode({phone:this.phone})
            if (res && res.code === 0) {
                this.$emit("setSmsToken",res.data);
            } else {
                if (res && res.msg) {
                    ToastManager.showSuccess(res.msg.toString());
                } else {
                    ToastManager.showError("获取验证码出错，请稍后重试！")
                }
                this.clearSelfInterval();
            }
        },

        startCount() {
            if (!isValidPhone(this.phone.toString())) {
                return;
            }
            this.startCountDown()
            this.getSmsCode();
        },


        startCountDown() {
            // 计时器正在进行中，退出函数
            if (this.intervalId != null) {
                return;
            }
            // 计时器为空，操作
            this.intervalId = setInterval(() => {
                this.countDown--;
                this.verifyBtnTxt = this.countDown + 'S';
                if (this.countDown === 0) {
                    this.clearSelfInterval();
                }
            }, 1000);
            this.verifyBtnTxt = this.countDown + 'S';
        },


        clearSelfInterval() {
            this.countDown = 60;
            this.verifyBtnTxt = '获取验证码';
            if (this.intervalId) {
                clearInterval(this.intervalId); // 清除计时器
            }
            this.intervalId = null; // 设置为null
        },
    }
}
</script>

<style scoped>

</style>
