<template>
<div
    :class="[isSelect ? 'bg-gray-100' : 'hover:bg-gray-100']"
    class="flex flex-row h-10 mb-2 cursor-pointer px-3 mx-3  rounded" @click="goTopic">
    <base-icon :icon="mdiMessage" :icon-size="20"></base-icon>
    <div ref="inputRef" class="h-10 ml-2 inline-block leading-10 truncate w-full" :contenteditable="isEditing" @keydown="enterInput">{{topic.name}}</div>
    <div class="flex-1"/>
    <base-icon class="mr-3.5" :icon="mdiPencilOutline" :icon-size="20" v-if="isSelect && !isEditing" @click.native.stop="onEdit"></base-icon>
    <base-icon  :icon="mdiDeleteOutline" :icon-size="20" v-if="isSelect && !isEditing" @click.native.stop="deleteTopic"></base-icon>
    <base-icon class="mr-3.5" :icon="mdiCheck" :icon-size="20" v-if="isEditing && isSelect" @click.native.stop="submit"></base-icon>
    <base-icon :icon="mdiClose" :icon-size="20" v-if="isEditing && isSelect" @click.native.stop="onCancel"></base-icon>
</div>
</template>

<script>
import BaseIcon from "@/components/button/BaseIcon.vue";
import {mdiMessageOutline,mdiDeleteOutline,mdiCheck,mdiClose,mdiPencilOutline} from "@mdi/js";
import {isMobile} from "@/utils/tools";

export default {
    name: "TopicItem",
    components: { BaseIcon},
    props: {
        topic: {

        }
    },
    computed: {
      isSelect() {
          return this.topic.id.toString() === this.$route.params.topicId;
      }
    },
    data() {
        return {
            mdiMessage:mdiMessageOutline,
            mdiDeleteOutline:mdiDeleteOutline,
            mdiPencilOutline:mdiPencilOutline,
            mdiCheck:mdiCheck,
            mdiClose:mdiClose,
            isEditing:false,
        }
    },

    methods: {
        goTopic() {
            this.$router.push({
                path:`/chat/view/${this.topic.friendId}/${this.topic.id}`,
            })
        },
        deleteTopic() {
          this.$emit("deleteTopic",this.topic);
        },
        submit() {
            this.$emit("saveTopic",{
                id:this.topic.id,
                name:this.$refs.inputRef.innerText,
                callback:this.onCancel
            })
        },

        onCancel() {
            this.$refs.inputRef.innerText = this.topic.name;
            this.isEditing = false;
        },

        onEdit() {
          this.isEditing = true;
          this.$nextTick(()=>{
              this.$refs.inputRef.focus();
          })
        },

        enterInput(event) {
            if (event.key === "Enter") {
                event.preventDefault();

                // 发送消息
                this.submit();

                // 当设备为移动端时，发送后关闭键盘
                if (isMobile()) (event.target).blur();
            }
        },
    }
}
</script>

<style scoped>

</style>
