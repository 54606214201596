import {getLocalUserInfo} from "@/utils/auth";

const figures = [
    {
        id:"mai",
        name:"OKR陪伴式教练@小麦",
        label:"知识:::OKR:::教练",
        invitationCode:"CareerX",
        desc:"服务内容：" +
            "致力于识别并激发人才潜能，引领更多企业保持高效、持续增长、不断创新。\n" +
            "服务特色：" +
            "创新人才培训-设计思维、绩效与目标管理-OKR陪伴式赋能、组织创新转型-赛训战一体。\n" +
            "客户案例：" +
            "阿里大文娱、腾讯云雀、滴滴、毫末智行、神州租车、晖致、拜耳、诺华、强生、玛氏、LVMH、欧莱雅、中国银联、光大银行、中国银行、捷信、广州地铁集团等。",
        type:"figure",
        avatar: "https://img.gouhuotech.com/file/okrCopilot/avatar/xiaomai-avatar.jpeg",
        chatList: [
            {
                user: {
                    avatar: "https://img.gouhuotech.com/file/okrCopilot/avatar/xiaomai-avatar.jpeg"
                },
                isSelf: false,
                content: "您好，我是OKR陪伴式教练@小麦，很高兴为您答疑解惑。您有任何OKR咨询相关问题，都可以问我。"
            },
        ]
    },
    {
        id:"ryan",
        name:"OKR陪伴式教练@Ryan",
        label:"知识:::OKR:::教练",
        invitationCode:"CareerX",
        type:"figure",
        desc:"服务内容：" +
            "致力于识别并激发人才潜能，引领更多企业保持高效、持续增长、不断创新。\n" +
            "服务特色：" +
            "创新人才培训-设计思维、绩效与目标管理-OKR陪伴式赋能、组织创新转型-赛训战一体。\n" +
            "客户案例：" +
            "阿里大文娱、腾讯云雀、滴滴、毫末智行、神州租车、晖致、拜耳、诺华、强生、玛氏、LVMH、欧莱雅、中国银联、光大银行、中国银行、捷信、广州地铁集团等。",
        avatar: "https://img.gouhuotech.com/file/okrCopilot/avatar/ryan-avatar.jpeg",
        chatList: [
            {
                user: {
                    avatar: "https://img.gouhuotech.com/file/okrCopilot/avatar/ryan-avatar.jpeg"
                },
                isSelf: false,
                content: "您好，我是OKR陪伴式教练@Ryan，很高兴为您答疑解惑。您有任何OKR咨询相关问题，都可以问我。"
            },
        ]
    },

    {
        id:"keke",
        name: "共创式OKR教练@克克",
        avatar:"https://img.gouhuotech.com/file/okrCopilot/avatar/keke-avatar.jpg",
        label:"知识:::OKR:::教练",
        type:"figure",
        desc: "服务内容：" +
            "为企业提供从使命愿景到业务结果的全面解决方案。\n" +
            "服务特色：" +
            "共创式OKR管理（OKR-C-ToDo，ME-WE-ALL）\n" +
            "客户案例：" +
            "阿里巴巴、百度、网易、美团、中国电信、中国银行、东风汽车、上汽集团、平安银行、通威集团、vivo、大润发、京东方、巴斯夫等。",
        chatList:[
            {
                user: {
                    avatar: "https://img.gouhuotech.com/file/okrCopilot/avatar/keke-avatar.jpg"
                },
                isSelf: false,
                content: "您好，我是共创式OKR@克克，很高兴为您答疑解惑。您有任何OKR咨询相关问题，都可以问我。"
            },

        ]
    },
    {
        id:"ben",
        name:"AI教练@Ben.Lamorte",
        avatar:"https://img.gouhuotech.com/file/okrCopilot/avatar/ben-avatar.jpeg",
        label:"知识:::OKR:::教练",
        type:"figure",
        desc:"《OKR：源于英特尔和谷歌的目标管理利器》作者\n" +
            "《OKR教练实战手册》作者\n" +
            "美国OKR教练网（OCN） 创始人\n",
        chatList: [
            {
                user: {
                    avatar: "https://img.gouhuotech.com/file/okrCopilot/avatar/ben-avatar.jpeg"
                },
                isSelf: false,
                content: "您好，我是AI教练@Ben.Lamorte，很高兴为您答疑解惑。您有任何OKR咨询相关问题，都可以问我。"
            },
        ]
    },
    {
        id:"okr-copilot",
        name:"OKR Copilot",
        avatar:"https://source.boringavatars.com/beam/?square",
        label:"工具:::OKR",
        type:"tool",
        isSystem:true,
        desc:"一款基于第一性原理的智能化目标拆解工具，帮助你找出实现目标的基本元素、内在原理、解决方案以及关键结果。\n\n"+
            "   · 拆解目标O包含的基本元素\n" +
            "   · 找出元素包含的内在原理\n" +
            "   · 根据内在原理，提出相对应的假设\n" +
            "   · 基于假设，制定解决方案\n" +
            "   · 基于解决方案，确定关键结果KR",
        cotList: [
            {stage:0,title:"你的目标是什么？请用一句话描述",children:[]},
            {stage:1,title:"拆解出目标包含的基本元素",children:[]},
            {stage:2,title:"找出元素包含的内在原理",children:[]},
            {stage:3,title:"根据内在原理，提出相对应的假设",children:[]},
            {stage:4,title:"基于假设，制定解决方案",children:[]},
            {stage:5,title:"基于解决方案，确定关键结果KR",children:[]},
        ],
        chatList: [
            {
                user: {
                    avatar: "https://source.boringavatars.com/beam/?square"
                },
                isSelf: false,
                content: "您好，我是篝火智能的OKR Copilot，我可以帮助你深度拆解目标，洞察其内在的原理，找出创新性的解决方案。"
            },
        ]
    },
    {
        id:"scamper",
        name:"创新引导师",
        avatar:"https://img.gouhuotech.com/file/okrCopilot/avatar/scamper-avatar.png",
        label:"工具:::引导师",
        type:"tool",
        desc: "通过奔驰创新法（SCAMPER）帮助你寻找不一样的实现路径。",
        chatList: [
            {
                user: {
                    avatar: "https://img.gouhuotech.com/file/okrCopilot/avatar/scamper-avatar.png"
                },
                isSelf: false,
                content: "您好，我是您的创新引导师，我可以帮助您找到创新的解决方案。"
            },
        ]
    },
    {
        id:"chat-tree",
        name:"Chat Tree",
        avatar:"https://source.boringavatars.com/beam/?square",
        label:"工具:::聊天",
        desc:"探索未知就像一次信息之旅。我们基于大语言的聊天工具以独特的树形布局为您打开一扇全新的门户，让您沿着问题与答案的分支逐渐深入探索。每个问题都可能引出多个方向的回答，而我们的设计让您能轻松回溯并找到所有可能的答案。从未有过如此直观、灵活的方式帮助您寻找答案和深化理解，不管是学习、工作还是日常生活的疑问，都能在这棵知识之树上找到解答。",
        type:"tool",
    },
    {
        id:"okr-review",
        name:"OKR复盘引导师",
        avatar:"https://source.boringavatars.com/beam/?square",
        label:"工具:::OKR:::引导师",
        type:"tool",
        upcoming:true,
        chatList: [
            {
                user: {
                    avatar: "https://source.boringavatars.com/beam/?square"
                },
                isSelf: false,
                content: "您好，我是篝火智能的OKR复盘引导师，我可以引导你复盘你的OKR，让经验成为你的能力。"
            },
        ]
    },
    {
        id:"dao",
        name:"AI顾问@稻盛和夫",
        avatar:"https://img.gouhuotech.com/file/okrCopilot/avatar/dao-avatar.jpg",
        label:"知识:::管理:::顾问",
        type:"figure",
        upcoming:true,
        chatList: [
            {
                user: {
                    avatar: "https://img.gouhuotech.com/file/okrCopilot/avatar/dao-avatar.jpg"
                },
                isSelf: false,
                content: "您好，我是AI顾问@稻盛和夫，很高兴为您答疑解惑。您有任何企业相关问题，都可以问我。"
            },
        ]
    },
    {
        id:"swot",
        name:"SWOT引导师",
        avatar:"https://source.boringavatars.com/beam/?square",
        label:"工具:::战略:::引导师",
        type:"tool",
        upcoming:true,
        chatList: [
            {
                user: {
                    avatar: "https://source.boringavatars.com/beam/?square"
                },
                isSelf: false,
                content: "您好，我是您的SWOT引导师，我可以引导您分析您和您的企业的优势、劣势、机会和挑战，为您找到潜在的战略举措。"
            },
        ]
    },

]

const friends = [
    {
        id:"okr-copilot",
        name:"OKR Copilot",
        avatar:"https://source.boringavatars.com/beam/?square",
        label:"工具:::OKR",
        type:"tool",
        isSystem:true,
        cotList: [
            {stage:0,title:'',color:'bg-sky-100',desc:"你的目标是什么？请用一句话描述",children:[]},
            {stage:1,title:'',color:'bg-red-50',desc:"拆解出目标包含的基本元素",children:[]},
            {stage:2,title:'',color:'bg-red-50',desc:"找出元素包含的内在原理",children:[]},
            {stage:3,title:'',color:'bg-red-50',desc:"根据内在原理，提出相对应的假设",children:[]},
            {stage:4,title:'',color:'bg-red-50',desc:"基于假设，制定解决方案",children:[]},
            {stage:5,title:'',color:'bg-red-50',desc:"基于解决方案，确定关键结果KR",children:[]},
        ],
        chatList: [
            {
                user: {
                    avatar: "https://source.boringavatars.com/beam/?square"
                },
                isSelf: false,
                content: "您好，我是篝火智能的OKR Copilot，我能够使用第一性原理，帮助你深度拆解目标，洞察其内在的原理，找出创新性的解决方案。"
            },
            {
                user: {
                    avatar: "https://source.boringavatars.com/beam/?square"
                },
                isSelf: false,
                content: "让我们开始吧！你的目标是什么？请用一句话描述。"
            },
        ]
    },
    {
        id:"mai",
        name:"OKR陪伴式教练@小麦",
        label:"知识:::OKR:::教练",
        type:"figure",
        invitationCode:"CareerX",
        avatar: "https://img.gouhuotech.com/file/okrCopilot/avatar/xiaomai-avatar.jpeg",
        chatList: [
            {
                user: {
                    avatar: "https://img.gouhuotech.com/file/okrCopilot/avatar/xiaomai-avatar.jpeg"
                },
                isSelf: false,
                content: "您好，我是OKR陪伴式教练@小麦，很高兴为您答疑解惑。您有任何OKR咨询相关问题，都可以问我。"
            },
        ]
    },
    {
        id:"ryan",
        name:"OKR陪伴式教练@Ryan",
        label:"知识:::OKR:::教练",
        type:"figure",
        invitationCode:"CareerX",
        avatar: "https://img.gouhuotech.com/file/okrCopilot/avatar/ryan-avatar.jpeg",
        chatList: [
            {
                user: {
                    avatar: "https://img.gouhuotech.com/file/okrCopilot/avatar/ryan-avatar.jpeg"
                },
                isSelf: false,
                content: "您好，我是OKR陪伴式教练@Ryan，很高兴为您答疑解惑。您有任何OKR咨询相关问题，都可以问我。"
            },
        ]
    },
    {
        id:"keke",
        name: "共创式OKR@克克",
        avatar:"https://img.gouhuotech.com/file/okrCopilot/avatar/keke-avatar.jpg",
        label:"知识:::OKR:::教练",
        type:"figure",
        chatList:[
            {
                user: {
                    avatar: "https://img.gouhuotech.com/file/okrCopilot/avatar/keke-avatar.jpg"
                },
                isSelf: false,
                content: "您好，我是共创式OKR@克克，很高兴为您答疑解惑。您有任何OKR咨询相关问题，都可以问我。"
            },

        ]
    },
    {
        id:"ben",
        name:"AI教练@Ben.Lamorte",
        avatar:"https://img.gouhuotech.com/file/okrCopilot/avatar/ben-avatar.jpeg",
        label:"知识:::OKR:::教练",
        type:"figure",
        chatList: [
            {
                user: {
                    avatar: "https://img.gouhuotech.com/file/okrCopilot/avatar/ben-avatar.jpeg"
                },
                isSelf: false,
                content: "您好，我是AI教练@Ben.Lamorte，很高兴为您答疑解惑。您有任何OKR咨询相关问题，都可以问我。"
            },
        ]
    },
    {
        id:"scamper",
        name:"创新引导师",
        avatar:"https://img.gouhuotech.com/file/okrCopilot/avatar/scamper-avatar.png",
        label:"工具:::引导师",
        type:"tool",
        chatList: [
            {
                user: {
                    avatar: "https://img.gouhuotech.com/file/okrCopilot/avatar/scamper-avatar.png"
                },
                isSelf: false,
                content: "您好，我是您的创新引导师，我可以帮助您找到创新的解决方案。"
            },
        ]
    },

    {
        id:"chat-tree",
        name:"Chat Tree",
        avatar:"https://source.boringavatars.com/beam/?square",
        label:"工具:::聊天",
        type:"tool",
        desc:"以树的形式对话AI，找出你想要的所有答案！",
        chatList: [
            {
                user: {
                    avatar: "https://source.boringavatars.com/beam/?square"
                },
                isSelf: false,
                content: "您好，我是您的AI助手，您有任何问题都可以问我。"
            },
        ],
    },
]


export function  getFigures() {
    const userInfo = getLocalUserInfo();
    if (userInfo.invitationCode) {
        let ans = [];
        figures.forEach((figure)=>{
            if (figure.invitationCode === userInfo.invitationCode || figure.isSystem) {
                ans.push(figure);
            }
        })
        return ans;
    } else {
        return figures;
    }
}

export function getFriends() {
    const userInfo = getLocalUserInfo();
    if (userInfo && userInfo.invitationCode) {
        let ans = [];
        friends.forEach((friend)=>{
            if (friend.invitationCode === userInfo.invitationCode || friend.isSystem) {
                ans.push(friend);
            }
        })
        return ans;
    } else {
        return friends;
    }
}

export function getTmpFriendIdByName(name) {
    if (name === 'OKR陪伴式教练@小麦') {
        return 'mai';
    } else if (name === 'OKR陪伴式教练@Ryan') {
        return 'ryan'
    } else if (name === '共创式OKR教练@克克') {
        return 'keke'
    } else if (name === 'AI教练@Ben.Lamorte') {
        return 'ben'
    }
    return null;
}
