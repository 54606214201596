<template>
    <account-item-card title="服务订阅">
        <div class="w-full h-fit grid gap-4 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
            <div class="h-80 p-6 flex flex-col rounded-xl bg-green-50  shadow lg:hover:shadow-xl transition-shadow duration-500  cursor-pointer">
                <div class="text-xl font-bold">体验包</div>
                <div class="p-1 mt-2">100点/10元</div>
                <div class="p-1 mt-2">适合于新手用户</div>
                <div class="bg-red-400 w-fit rounded p-1 text-white mt-2">有效期：1个月</div>
                <div class="flex-1"/>
                <div class="flex flex-row-reverse">
                    <base-button type="submit" label="购买" color="info" class="text-base"
                                 @click.native.stop="show(10)"/>
                </div>
            </div>
            <div class="h-80 p-6 flex flex-col rounded-xl bg-sky-50 shadow lg:hover:shadow-xl transition-shadow duration-500  cursor-pointer">
                <div class="text-xl font-bold">普通包</div>
                <div class="pt-1 mt-2">1000点/100元</div>
                <div class="pt-1 mt-2">适合于需求量较大的普通用户</div>
                <div class="bg-red-400 mt-2 w-fit rounded p-1 text-white">有效期：永久</div>
                <div class="flex-1"/>
                <div class="flex flex-row-reverse">
                    <base-button type="submit" label="购买" color="info" class="text-base"
                                 @click.native.stop="show(100)"/>
                </div>
            </div>
            <div class="h-80 p-6 flex flex-col rounded-xl bg-orange-50 shadow lg:hover:shadow-xl transition-shadow duration-500  cursor-pointer">
                <div class="text-xl font-bold">月度畅聊包</div>
                <div class="pt-1 mt-2">不限/999元</div>
                <div class="pt-1 mt-2">适合于需求量较大的专业用户</div>
                <div class="pt-1 mt-2">支持自定义机器人和工具（共三个）</div>
                <div class="bg-red-400 mt-2 w-fit rounded p-1 text-white" >有效期：1个月</div>
                <div class="flex-1"/>
                <div class="flex flex-row-reverse">
                    <base-button type="submit" label="即将上线" color="info" class="text-base" :disabled="true"
                                 @click.native.stop="show(999)"/>
                </div>
            </div>
        </div>
        <base-dialog ref="baseDialogRef">
            <div slot="body" class="w-full h-full lg:w-7/12 lg:h-fit flex flex-col bg-white shadow-2xl p-5">
                <div class="text-xl font-bold">订单支付</div>
                <div class="w-full mt-5" style="height: 1px;background: #EEEEEE;" />
                <div class="w-full h-full flex flex-col" >
                    <div class="flex flex-row mt-5">
                        <div class="text-base">支付金额</div>
                        <div class="text-base font-bold text-red-700">￥{{amountMoney}}</div>
                    </div>
                    <div class="text-base mt-5">支付方式</div>
                    <div class="flex flex-row mt-2">
                        <payment-way :icon="ic_alipay" :isSelected="payType === 1" :name="'支付宝'" @click.native.prevent="selectPayType(1)" />
                        <payment-way class="ml-5" :icon="ic_wechat" :isSelected="payType === 2" :name="'微信支付'" @click.native.prevent="selectPayType(2)"
                                     style="margin-left: 15px" />
                    </div>
                    <div class="flex flex-col mt-5 items-center justify-center" style="width:320px;min-height: 260px" v-loading="isLoading">
                        <div id="qrcode" class="qrcode" v-show="payId !== null"></div>
                        <div class="text-base mt-2" v-if="payId !== null">{{payType === 1 ? '使用支付宝扫一扫' : '使用微信扫一扫'}}</div>
                        <div class="text-base text-red-700 mt-2" v-if="payId !== null">{{`二维码有效期：${termValidity}`}}</div>
                    </div>
                </div>
                <div class="w-full" style="height: 1px;background: #EEEEEE;" />
                <div class="flex flex-row-reverse items-center mt-5">
                    <base-button :outline="true" type="submit" :label="isPaymentSuccess ? '知道了': '取消支付'" color="info"
                                 @click.native.stop="close"
                                 class="text-base ml-10"/>
                </div>
            </div>
        </base-dialog>
    </account-item-card>
</template>

<script>
import AccountItemCard from "@/views/account/commponent/AccountItemCard.vue";
import BaseButton from "@/components/button/BaseButton.vue";
import BaseDialog from "@/components/dialog/BaseDialog.vue";
import PaymentWay from "@/views/account/commponent/PaymentWay.vue";
import QRCode from 'qrcodejs2';
import {orderStatusQuery, payment} from "@/api/account";
import ToastManager from "@/utils/ToastManager";

export default {
    name: "ServiceCard",
    components: {PaymentWay, BaseDialog, BaseButton, AccountItemCard},
    data() {
      return {
          isShowDialog:false,
          isLoading:false,
          payType:1,
          payId:null,
          ch:1,
          qrcode:null,
          amountMoney: 10,
          termValidity: "",
          iconInfo:require('@/assets/account/ic_info.png'),
          ic_alipay:require('@/assets/account/ic_payment_alipay.png'),
          ic_wechat:require('@/assets/account/ic_payment_wechat.png'),
          ic_success:require('@/assets/account/ic_payment_success.png'),
          timer:null,
      }
    },
    beforeDestroy() {
        this.clearTimer();
    },
    methods: {
        show(amountMoney) {
            this.amountMoney = amountMoney;
            this.isShowDialog = true;
            this.$refs.baseDialogRef.showDialog();
            this.getUrl();
        },
        close() {
            this.clearTimer();
            this.payId = null;
            this.isShowDialog = false;
            this.$refs.baseDialogRef.closeDialog();
        },

        clearTimer() {
            if (this.timer) {
                clearInterval(this.timer)
            }
            this.timer = null;
        },

        setTimer() {
            if (this.timer) {
                this.clearTimer();
            }

            this.timer = setInterval(() => {
                this.orderStatusQuery();
            }, 5000);
        },

        async orderStatusQuery() {
            const res = await orderStatusQuery({
                id:this.payId,
            });
            if (res.code === 0) {
                if (res.data === 1) {
                    this.close();
                    ToastManager.showSuccess("支付成功");
                } else if (res.data === 4) {
                    this.clearTimer();
                    ToastManager.showSuccess(res.msg);
                }
            } else {
               this.clearTimer();
               ToastManager.showError(res.msg);
            }

            let exp = new Date(this.termValidity);
            if (Date.now() > exp.getTime()) {
                this.close();
                ToastManager.showWarning("二维码已过期，请重新点击购买！");
            }
        },

        selectPayType(payType) {
            this.payType = payType;
            this.payId = null;
            this.getUrl();
        },

        async getUrl() {
            const res = await payment({
                amountMoney:this.amountMoney,
                type:this.payType,
            })
            if (res.code === 0) {
                this.payId = res.data.id;
                this.termValidity = res.data.termValidity;
                this.setQrCode(res.data.qrCode);
                this.setTimer();
            } else {
                ToastManager.showError(res.msg);
            }
        },


        setQrCode(url) {
            this.$nextTick(() => {
                if (this.qrcode !== null) {
                    this.qrcode.makeCode(url);
                } else {
                    this.qrcode = new QRCode(document.getElementById('qrcode'), {
                        width: 160,
                        height: 160,
                        text: url, // 二维码地址
                        colorDark: '#000',
                        colorLight: '#fff',
                        correctLevel: QRCode.CorrectLevel.H,
                    });
                }
            });
        },

    }

}
</script>

<style scoped>

</style>
