import {getToken} from "@/utils/auth";

const URL="https://knowledge-gouhuoai-server-lkrltksder.cn-beijing.fcapp.run/v1/knowledge"

export async function chatWithKnowledge({friendId,content,signal}) {
    const path = "/chat";
    const token = getToken();

    const response = await fetch(URL+path,{
        method: "POST",
        signal:signal,
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
        },
        body: JSON.stringify({bot_id:friendId,content}),
    })
    return await response.json();
}

export async function uploadContent({friendId,content,signal}) {
    const path = "/uploadContent";
    const token = getToken();

    const response = await fetch(URL+path,{
        method: "POST",
        signal:signal,
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
        },
        body: JSON.stringify({bot_id:friendId,content}),
    })
    return await response.json();
}

export async function uploadByUrls({friendId,urls,signal}) {
    const path = "/uploadByUrls";
    const token = getToken();

    const response = await fetch(URL+path,{
        method: "POST",
        signal:signal,
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
        },
        body: JSON.stringify({bot_id:friendId,urls}),
    })
    return await response.json();
}

export async function uploadByFile({friendId,file,signal}) {
    const path = "/uploadByFile" + `?bot_id=${friendId}`;
    const token = getToken();

    let formData = new FormData()
    formData.append('file',file)
    const response = await fetch(URL+path,{
        method: "POST",
        signal:signal,
        headers: {
            "Authorization": `Bearer ${token}`,
        },
        body: formData
    })
    return await response.json();
}

export async function deleteUploaded({friendId,docId,signal}) {
    const path = "/deleteUploaded";
    const token = getToken();

    const response = await fetch(URL+path,{
        method: "POST",
        signal:signal,
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
        },
        body: JSON.stringify({
            bot_id:friendId,
            doc_id: docId,
        })
    })
    return await response.json();
}

export async function getUploadedList({friendId,pageIndex,pageSize,signal}) {
    const path = "/getUploadedList";
    const token = getToken();

    const response = await fetch(URL+path,{
        method: "POST",
        signal:signal,
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
        },
        body: JSON.stringify({
            bot_id:friendId,
            page_index:pageIndex,
            page_size:pageSize,
        })
    })
    return await response.json();
}
