<template>
    <div class="shadow-xl w-full lg:w-16 h-16 lg:h-full bg-slate-100
    absolute bottom-0 left-0 flex flex-row lg:flex-col lg:pt-10 z-40">
        <div class="grow flex flex-row items-center justify-center lg:grow-0 " v-for="(item, index) in appList"
             :key="item.icon">
            <app-item :icon="item.icon" :is-selected="index === curIndex" @click.native="select(index)"/>
        </div>
    </div>
</template>

<script>
import {mdiMessageProcessingOutline,mdiApps, mdiAccount} from "@mdi/js";
import AppItem from "@/layout/component/AppItem.vue";

export default {
    name: "AppBar",
    components: {AppItem},
    data() {
        return {
            appList: [
                {
                    icon: mdiMessageProcessingOutline,
                    path: '/chat',
                    name: 'chat',
                },
                {
                    icon: mdiApps,
                    path: '/space',
                    name: 'space',
                },
                {
                    icon: mdiAccount,
                    path: '/account',
                    name: 'account',
                }
            ],
            curIndex: 0,
        }
    },

    mounted() {
        this.update();
    },

    watch: {
        $route() {
            this.update()
        }
    },

    methods: {

        update() {
            this.appList.forEach((app, index) => {
                if (this.$route.path.indexOf(app.path) !== -1) {
                    this.curIndex = index;
                }
            })
        },

        select(index) {
            if (this.curIndex !== index) {
                this.curIndex = index;
                this.$router.push(this.appList[index].path)
            }
        }
    }
}
</script>

<style scoped>

</style>
