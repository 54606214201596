<template>
    <div class="w-full h-full flex flex-col cursor-grab" style="background-color: #F9FAFB"
         @wheel="handleWheel" id="whiteBoard"
    >
        <list-header id="header" @saveImg="saveImg" :enable-save-img="true"/>
        <div
                class="w-full h-full relative overflow-visible px-5" :style="{
            'transform-origin':`${originX}px ${originY}px`,
            'transform':`translate(${x}px, ${y}px) scale(${scale})`}">
            <div class="absolute w-full h-full left-0 top-0 z-10">
                <okr-copilot-node :ref="'nodeRef'+ node.id" :style="{left:node.x + 'px', top:node.y + 'px'}"
                                  @clickRightMenu="clickRightMenu"
                                  @clickMenu="clickMenu"
                                  @showDrawer="showDrawer"
                                  @deleteNode="deleteNode(index)"
                                  @goTop="goTop(index)"
                                  @updateContent="updateContent"
                                  @updatePosition="updatePosition"
                                  v-for="(node,index) in chatList" :key="node.id" :node="node"/>
                <chat-link v-for="(link) in linkList" :link="link" :key="link.id"/>
            </div>
        </div>
        <base-drawer direction="btt" v-show="isShowDrawer" class="lg:hidden">
            <div class="flex-col flex w-full rounded-t-xl shadow bg-white p-5" v-clickoutside="closeDrawer"
                 v-if="child.node">
                <div class="text-base text-gray-500 p-3">{{ child.node.menusTitle }}</div>
                <div class="text-xl text-black py-5 px-3 hover:bg-gray-200 rounded cursor-pointer"
                     v-for="(item) in child.node.menus" :key="item" @click="clickMenu(child,item)">{{ item.name }}
                </div>
            </div>
        </base-drawer>
    </div>
</template>

<script>
import ListHeader from "@/views/chat/component/ListHeader.vue";
import OkrCopilotNode from "@/views/chat/component/OkrCopilotNode.vue";
import {ChatTreeStyle} from "@/utils/common";
import {nanoid} from "nanoid";
import {normalizeScale, normalizeWheel} from "@/utils/tools";
import BaseDrawer from "@/components/drawer/BaseDrawer.vue";
import ChatLink from "@/views/chat/component/ChatLink.vue";
import {askCopilot} from "@/api/ai";
import ToastManager from "@/utils/ToastManager";
import Broadcaster from "@/utils/Broadcaster";
import html2canvas from "html2canvas";
import Hammer from "hammerjs";
import {getLocalUserInfo} from "@/utils/auth";
import {getChatList, saveChat, saveTopic} from "@/api/chat";

export default {
    name: "OkrCopilotTree",
    components: {ChatLink, BaseDrawer, OkrCopilotNode, ListHeader},
    data() {
        return {
            last: {x: null, y: null},
            isDrag: false,
            scale: 1.0,
            scales: [1, 2, 4, 8, 16, 32, 64, 100, 200, 400],
            x: 10,
            y: window.innerHeight / 3,
            originX: 0,
            originY: 0,
            maxWidth: 500,
            maxHeight: 500,
            isShowDrawer: false,
            touches: [],
            child: {},
            topicId: this.$route.params.topicId,
            prompts: [
                {
                    question: "你的目标是什么？请用一句话描述：",
                    placeholder: "做...达成...(写清楚做这件事的目的)",
                    title: "拆解出目标包含的基本元素：",
                },
                {
                    question: "拆解出目标包含的基本元素：",
                    placeholder: "请写下你拆解的元素",
                    title: "找出元素包含的内在原理：",
                },
                {
                    question: "找出元素包含的内在原理：",
                    placeholder: "请写下你拆解元素的内在原理",
                    title: "根据内在原理，提出相对应的假设：",
                },
                {
                    question: "根据内在原理，提出相对应的假设：",
                    placeholder: "请写下你的假设",
                    title: "基于假设，制定解决方案：",
                },
                {
                    question: "基于假设，制定解决方案：",
                    placeholder: "请写下你的解决方案",
                    title: "基于解决方案，确定关键结果KR：",
                },
                {
                    question: "基于解决方案，确定关键结果KR：",
                    placeholder: "请写下你的KR",
                    title: "基于关键结果KR，制定出你的行动计划TODO：",
                },
                {
                    question: "基于关键结果KR，制定出你的行动计划TODO：",
                    placeholder: "请写下你的TODO",
                    title: null,
                },
            ],
            linkList: [],
            set: new Set(),
            chatList: [
                {
                    parentId: null,
                    index: 0,
                    id: nanoid(), // 前端ID，只用于标识
                    nodeId: null, // 真实的ID，保存在后端
                    stage: 0,
                    question: "你的目标是什么？请用一句话描述",
                    placeholder: "做...达成...(写清楚做这件事的目的)",
                    answer: "",
                    questionContenteditable: false,
                    answerContenteditable: true,
                    isMore: false,
                    x: 0,
                    y: 0,
                    menusTitle: "拆解出目标包含的基本元素：",
                    menus: [
                        {cmd: 'manual', name: '手动拆解'},
                        {cmd: 'copilot', name: 'Copilot拆解'},
                    ],
                    children: [],
                    manager: null,
                }
            ]
        }
    },
    mounted() {
        this.$nextTick(() => {
            let manager = new Hammer(this.$el);


            //拖动画板
            manager.get('pan').set({direction: Hammer.DIRECTION_ALL});
            let last = {x: null, y: null}
            manager.on("panmove", (event) => {

                if (last.x !== null && last.y !== null) {
                    this.x += (event.center.x - last.x);
                    this.y += (event.center.y - last.y);
                    last = {
                        x: event.center.x,
                        y: event.center.y,
                    }
                }
                last = {
                    x: event.center.x,
                    y: event.center.y,
                }
            });
            manager.on("panstart", (event) => {
                last = {
                    x: event.center.x,
                    y: event.center.y,
                }

                this.last = last;
            })

            let lastScale = this.scale


            //手机端缩放
            manager.get('pinch').set({enable: true});
            manager.on("pinch pinchmove", (ev) => {
                // 根据捏合手势的scale属性调整缩放比例
                this.scale = normalizeScale(lastScale * ev.scale);
            });

            manager.on('pinchend', () => {
                lastScale = this.scale;
            })

            this.manager = manager;
        })

        Broadcaster.$on("refreshChat", () => {
            this.refresh();
        })

        this.initData();
    },
    beforeDestroy() {
        Broadcaster.$off("refreshChat");
        this.manager.destroy();
    },
    methods: {
        async initData() {
            if (this.$route.params.topicId) {
                this.topicId = this.$route.params.topicId;
                const res = await getChatList({
                    size: 2000,
                    current: 1,
                    topicId: this.$route.params.topicId,
                })
                if (res.code === 0) {
                    this.initTree(res.data.records)
                } else {
                    ToastManager.showError("初始化失败，请刷新后重试！");
                }
            }
        },

        initTree(chats) {
            if (chats.length > 0) {
                let map = new Map();
                let rootId = null;
                chats.forEach((chat) => {
                    let tmp = {
                        children: [],
                        ...chat,
                    }
                    if (!chat.parentId) {
                        rootId = chat.id;
                    }
                    map.set(tmp.id, tmp)
                })

                chats.forEach((chat) => {
                    if (map.has(chat.parentId)) {
                        map.get(chat.parentId).children.push(map.get(chat.id));
                    }
                })

                this.chatList = [
                    {
                        parentId: null,
                        index: 0,
                        id: nanoid(),
                        nodeId: rootId,
                        stage: 0,
                        question: "你的目标是什么？请用一句话描述",
                        placeholder: "做...达成...(写清楚做这件事的目的)",
                        answer: map.get(rootId).content,
                        questionContenteditable: false,
                        answerContenteditable: true,
                        isMore: false,
                        x: 0,
                        y: 0,
                        menusTitle: "拆解出目标包含的基本元素：",
                        menus: [
                            {cmd: 'manual', name: '手动拆解'},
                            {cmd: 'copilot', name: 'Copilot拆解'},
                        ],
                        children: [],
                        manager: null,
                    }
                ]
                const insetChildren = (current, children, stage) => {
                    children.forEach((child) => {
                        let node = this.createNode({
                            x: 0, y: 0,
                            stage, answer: child.content, nodeId: child.id, index: child.sortKey, parentId: current.id,
                        })
                        current.children.push(node);
                        this.chatList.push(node);
                        if (child.children.length > 0) {
                            insetChildren(node, child.children, stage + 1);
                        }
                    })
                }
                insetChildren(this.chatList[0], map.get(rootId).children, 1);
            } else {
                this.x = 10;
                this.y = window.innerHeight / 3;
                this.topicId = null;
                this.set.clear();
                this.chatList = [
                    {
                        parentId: null,
                        index: 0,
                        id: nanoid(),
                        nodeId: null,
                        stage: 0,
                        question: "你的目标是什么？请用一句话描述",
                        placeholder: "做...达成...(写清楚做这件事的目的)",
                        answer: "",
                        questionContenteditable: false,
                        answerContenteditable: true,
                        isMore: false,
                        x: 0,
                        y: 0,
                        menusTitle: "拆解出目标包含的基本元素：",
                        menus: [
                            {cmd: 'manual', name: '手动拆解'},
                            {cmd: 'copilot', name: 'Copilot拆解'},
                        ],
                        children: [],
                        manager: null,
                    }
                ];
            }

            this.updatePosition();
        },


        saveImg() {
            const {height} = this.getBounds(this.chatList[0].id);
            this.x = 100;
            this.y = (this.maxHeight - height) / 2
            this.$nextTick(() => {
                html2canvas(document.getElementById('whiteBoard'),
                    {
                        width: this.maxWidth,
                        height: this.maxHeight,
                        x: 0,
                        y: 0,
                        async: true,
                        backgroundColor: '#F4F6FC',
                        scale: 2,
                        logging: true,
                        useCORS: true,
                        allowTaint: true,
                        ignoreElements: (element) => {
                            return element.id === 'header';
                        }
                    })
                    .then(canvas => {
                        let img = new Image();
                        img.crossOrigin = '*';
                        img.src = canvas.toDataURL('image/png');// toDataURL :图片格式转成 base64
                        //document.getElementById('capture').appendChild(img);
                        //如果你需要下载截图，可以使用a标签进行下载
                        //img.crossorigin = '*';
                        let a = document.createElement('a');
                        a.href = canvas.toDataURL('image/png');
                        a.download = 'OKR拆解' + nanoid();
                        a.click();
                        this.$message.success('保存成功！');
                    });
            })
        },


        refresh() {
            if (this.$route.params.topicId !== null) {
                this.$router.push({
                    path: `/chat/view/${this.$route.params.friendId}`
                })
            } else {
                this.x = 10;
                this.y = window.innerHeight / 3;
                this.topicId = null;
                this.set.clear();
                this.chatList = [
                    {
                        parentId: null,
                        index: 0,
                        id: nanoid(),
                        nodeId: null,
                        stage: 0,
                        question: "你的目标是什么？请用一句话描述",
                        placeholder: "做...达成...(写清楚做这件事的目的)",
                        answer: "",
                        questionContenteditable: false,
                        answerContenteditable: true,
                        isMore: false,
                        x: 0,
                        y: 0,
                        menusTitle: "拆解出目标包含的基本元素：",
                        menus: [
                            {cmd: 'manual', name: '手动拆解'},
                            {cmd: 'copilot', name: 'Copilot拆解'},
                        ],
                        children: [],
                        manager: null,
                    }
                ];
                this.updatePosition();
            }
        },

        drawLinkLine(id, parentId) {
            if (!parentId) return "";
            let fromBound = this.getBounds(parentId);
            let toBound = this.getBounds(id);
            const fromPoint = {
                x: fromBound.x + fromBound.width,
                y: fromBound.y + fromBound.height / 2,
            }
            const toPoint = {
                x: toBound.x,
                y: toBound.y + toBound.height / 2,
            }

            let left = Math.min(fromPoint.x, toPoint.x);
            let top = Math.min(fromPoint.y, toPoint.y);
            toPoint.x = toPoint.x - left;
            toPoint.y = toPoint.y - top;
            fromPoint.x = fromPoint.x - left;
            fromPoint.y = fromPoint.y - top;

            const toX = toPoint.x;
            const toY = toPoint.y;
            const cx = (fromPoint.x + toX) * 0.5;
            const cpX = cx;
            const cpY = fromPoint.y;
            const cpX2 = cx;
            const cpY2 = toPoint.y;
            return {
                id: nanoid(),
                path: fromPoint.y === toPoint.y ?
                    `M ${fromPoint.x} 1 L ${toX} 1` :
                    `M ${fromPoint.x} ${fromPoint.y} C ${cpX} ${cpY} ${cpX2} ${cpY2} ${toX} ${toY}`,
                left: left - 1,
                top: top,
                width: Math.max(2, Math.abs(fromPoint.x - toPoint.x)) + 1,
                height: Math.max(2, Math.abs(fromPoint.y - toPoint.y)) + 1,
            }
        },


        closeDrawer() {
            this.isShowDrawer = false;
            this.child = {};
        },

        showDrawer(child) {
            this.child = child;
            this.isRightMenu = false;
            this.isShowDrawer = true;
        },


        createNode({stage, x, y, parentId, index, answer, nodeId}) {
            return {
                parentId: parentId,
                id: nanoid(),
                nodeId: nodeId,
                stage: stage,
                index: index,
                question: this.prompts[stage].question,
                isMore: true,
                answer: answer,
                questionContenteditable: false,
                answerContenteditable: true,
                placeholder: this.prompts[stage].placeholder,
                x: x,
                y: y,
                menusTitle: this.prompts[stage].title,
                menus: [
                    {cmd: 'manual', name: '手动拆解'},
                    {cmd: 'copilot', name: 'Copilot拆解'},
                ],
                children: [],
            }
        },

        getBounds(id) {
            let height = this.$refs['nodeRef' + id][0].$el.offsetHeight;
            let width = this.$refs['nodeRef' + id][0].$el.offsetWidth;
            let x = this.$refs['nodeRef' + id][0].node.x;
            let y = this.$refs['nodeRef' + id][0].node.y;
            return {
                height: height,
                width: width,
                x: x,
                y: y,
            }
        },


        handleWheel(event) {
            let flag = event.ctrlKey || event.metaKey
            let originX = event.clientX;
            let originY = event.clientY;
            event.preventDefault();
            event.stopPropagation();
            event = normalizeWheel(event);

            if (flag) {
                let lastScale = this.scale;
                if (event.y < 0) {
                    this.scale = normalizeScale(this.scale - 0.1);
                } else {
                    this.scale = normalizeScale(this.scale + 0.1);
                }
                if (lastScale !== this.scale) {
                    this.originX = originX - this.x;
                    this.originY = originY - this.y;
                }
            } else {
                this.x += event.x;
                this.y += event.y;
            }


        },


        zoomIn() {

        },

        zoomOut() {

        },


        async addNodeManual(parentNode, answer = "") {
            //父节点如果未保存的话，先保存父节点
            if (!parentNode.node.nodeId) {
                await this.createChat(parentNode);
            }
            let node = this.createNode({
                answer: answer,
                stage: parentNode.node.stage + 1,
                x: parentNode.node.x,
                y: parentNode.node.y + parentNode.$el.offsetHeight,
                parentId: parentNode.node.id,
                index: parentNode.node.children.length === 0 ? 0 : parentNode.node.children[parentNode.node.children.length - 1].index + 1,
            });


            parentNode.node.children.push(node);
            this.chatList.push(node)
            this.updatePosition(async () => {
                parentNode.isSelect = false;
                //保存当前结点
                if (parentNode.node.nodeId) {
                    await this.createChat(this.$refs['nodeRef' + node.id][0]);
                }
            });
        },

        async getTopicId(content) {
            if (this.topicId) return this.topicId;

            const userInfo = getLocalUserInfo();
            const friendId = this.$route.params.friendId;

            const res = await saveTopic({
                name: content ? content : "未命名",
                userId: userInfo.id,
                friendId
            });
            if (res.code === 0) {
                this.topicId = res.data;
                return res.data;
            } else {
                return null;
            }
        },


        updateTopicName(name) {
            if (!name || name.length === 0) return;
            saveTopic({
                id: this.topicId,
                name: name,
            })
        },


        async createChat(currentNode) {
            if (this.set.has(currentNode.node.id)) return;
            this.set.add(currentNode.node.id);
            const topicId = await this.getTopicId(currentNode.computedValue.answer)
            const parentNodeId = currentNode.node.parentId ? this.$refs['nodeRef' + currentNode.node.parentId][0].node.nodeId : null;

            const res = await saveChat({
                content: currentNode.computedValue.answer,
                direction: 1,
                userId: getLocalUserInfo().id,
                friendId: this.$route.params.friendId,
                parentId: parentNodeId,
                topicId: topicId,
                sortKey: currentNode.node.index,
            })
            if (res.code === 0) {
                currentNode.node.nodeId = res.data;
            }
            this.set.delete(currentNode.node.id);
        },

        async updateChat(currentNode) {
            const res = await saveChat({
                id: currentNode.node.nodeId,
                content: currentNode.computedValue.answer,
                sortKey: currentNode.node.index,
            })
            if (res.code === 0) {
                console.log("update");
            }

            if (!currentNode.node.parentId) {
                this.updateTopicName(currentNode.computedValue.answer);
            }

        },

        async deleteChat(id) {
            const res = await saveChat({
                id: id,
                status: 0,
            })
            if (res.code === 0) {
                return true;
            } else {
                return false;
            }
        },

        updateContent(currentNode) {
            if (currentNode.node.nodeId) {
                this.updateChat(currentNode);
            } else {
                this.createChat(currentNode);
            }
        },


        async addNodeCopilot(parentNode) {
            if (parentNode.node.stage === 5) {
                await this.addTodoCopilot(parentNode);
                return;
            }
            let backgrounds = [];
            let targets = [];
            if (parentNode.node.stage === 0) {
                //横向拆解
                if (this.isRightMenu) {
                    parentNode.node.children.forEach((item) => {
                        let computedValue = this.$refs['nodeRef' + item.id][0].computedValue;
                        if (computedValue.answer && computedValue.answer.length > 0) {
                            backgrounds.push(computedValue.answer);
                        }
                    })
                }
                targets.push(parentNode);
            } else {

                //使用广度优先，遍历出所有需要拆解的结点
                const queue = [this.chatList[0]];
                let beginFlag = false;
                while (queue.length > 0) {
                    const node = queue.shift();
                    //遍历的目标结点时，开始记录
                    if (node.id === parentNode.node.id) {
                        beginFlag = true;
                    }
                    if (beginFlag && (node.stage === parentNode.node.stage)) {
                        targets.push(node)
                    }

                    //只遍历比目标层级高的结点，将其子节点入队列
                    if (node.stage < parentNode.node.stage) {
                        let sortedChildren = node.children.sort((a, b) => {
                            return a.index - b.index;
                        })
                        sortedChildren.forEach((child) => {
                            queue.push(child);
                        })
                    }
                    targets = targets.slice(0, 5);
                }

                targets.forEach((item) => {
                    let computedValue = this.$refs['nodeRef' + item.id][0].computedValue;
                    if (computedValue.answer && computedValue.answer.length > 0) {
                        backgrounds.push(computedValue.answer);
                    }
                })

            }

            if (this.$refs['nodeRef' + this.chatList[0].id][0].computedValue.answer.length === 0) {
                ToastManager.showError("目标不能为空！");
                return;
            }

            if (parentNode.node.stage !== 0 && backgrounds.length === 0) {
                ToastManager.showError("当前内容不能为空！");
                return;
            }

            const controller = new AbortController();

            Broadcaster.$emit("showThinkingView", () => {
                controller.abort();
            })
            const res = await askCopilot({
                stage: parentNode.node.stage,
                objective: this.$refs['nodeRef' + this.chatList[0].id][0].computedValue.answer,
                backgrounds: backgrounds,
                signal: controller.signal,
            })
            Broadcaster.$emit("closeThinkingView")
            if (res.code === 0) {
                if (res.data && res.data.length > 0) {
                    if (parentNode.node.parentId) {
                        for (let index = 0; index < res.data.length; ++index) {
                            if (index < targets.length) {
                                await this.addNodeManual(this.$refs['nodeRef' + targets[index].id][0], res.data[index]);
                            }
                        }
                    } else {
                        for (let index = 0; index < res.data.length; ++index) {
                            await this.addNodeManual(parentNode, res.data[index]);
                        }
                    }

                }
            } else {
                ToastManager.showError(res.msg);
            }
        },

        async addTodoCopilot(parentNode) {
            let backgrounds = [];
            let currentNode = parentNode;
            while (currentNode.node.parentId) {
                backgrounds.push(parentNode.computedValue.answer);
                currentNode = this.$refs['nodeRef' + currentNode.node.parentId][0];
            }
            backgrounds = backgrounds.reverse();
            if (this.$refs['nodeRef' + this.chatList[0].id][0].computedValue.answer.length === 0) {
                ToastManager.showError("目标不能为空！");
                return;
            }

            if (parentNode.node.stage !== 0 && backgrounds.length === 0) {
                ToastManager.showError("当前内容不能为空！");
                return;
            }

            const controller = new AbortController();

            Broadcaster.$emit("showThinkingView", () => {
                controller.abort();
            })
            const res = await askCopilot({
                stage: parentNode.node.stage,
                objective: this.$refs['nodeRef' + this.chatList[0].id][0].computedValue.answer,
                backgrounds: backgrounds,
                signal: controller.signal,
            })
            Broadcaster.$emit("closeThinkingView")
            if (res.code === 0) {
                if (res.data && res.data.length > 0) {
                    res.data.forEach((answer) => {
                        this.addNodeManual(parentNode, answer);
                    })
                }
            }
        },


        async deleteNode(index) {

            if (this.chatList[index].nodeId) {
                if (!await this.deleteChat(this.chatList[index].nodeId)) {
                    ToastManager.showError("删除失败");
                }
            }

            let parentId = this.chatList[index].parentId;
            let parentNode;

            this.chatList.forEach((node) => {
                if (node.id === parentId) {
                    parentNode = node;
                    return;
                }
            })
            if (!parentNode) return;
            parentNode.children.splice(this.chatList[index].index, 1);
            // parentNode.children.forEach((item, index) => {
            //     item.index = index;
            // })
            //this.chatList.splice(index, 1);
            this.deleteParentNode(this.chatList[index]);
            this.updatePosition();
        },

        goTop(index) {
            let parentId = this.chatList[index].parentId;
            let parentNode = this.$refs['nodeRef' + parentId][0].node;
            let [topNode] = parentNode.children.splice(this.chatList[index].index, 1);
            parentNode.children.unshift(topNode);
            if (parentNode.children.length > 1) {
                topNode.index = parentNode.children[1].index - 1;
            }

            this.updateChat(this.$refs['nodeRef' + topNode.id][0]);
            this.updatePosition();
        },


        deleteParentNode(parentData) {
            console.log(parentData)
            const getAllChildren = (node) => {
                this.deleteChat(node.nodeId);
                let children = [];
                node.children.forEach((item) => {
                    children.push(item);
                    children = children.concat(getAllChildren(item));
                })
                return children;
            }

            let deleteList = getAllChildren(parentData);
            deleteList.push(parentData);

            deleteList.forEach((item) => {
                this.chatList.splice(this.chatList.findIndex((node) => node.id === item.id), 1);
            })

        },


        updateNode(currentNode) {
            console.log(currentNode)
        },
        updatePosition(callback) {
            this.$nextTick(() => {
                const position = this.calculateHPosition();
                this.linkList.splice(0);
                this.chatList.forEach((node) => {
                    node.x = position[node.id].x;
                    node.y = position[node.id].y;
                    if (node.parentId) {
                        this.linkList.push(this.drawLinkLine(node.id, node.parentId))
                    }
                })
                if (callback) {
                    callback();
                }
            })
        },

        calculateHPosition() {
            const position = {};
            const spacing = ChatTreeStyle.spacing;

            // 深度优先遍历
            let infos = [...this.chatList];


            //从子节点开始计算所有节点的高度
            infos = infos.sort((a, b) => {
                if (a.stage === b.stage) {
                    return b.index - a.index;
                } else {
                    return b.stage - a.stage;
                }
            });
            let sizes = {};


            const getSize = (idx) => {
                const node = infos[idx];
                const {id} = node;
                const children = node.children;
                const len = children.length;
                const {height} = this.getBounds(id);
                // 父节点的高度边界等于 max（父节点的高度，子节点高度之和)
                if (len === 0) {
                    sizes[id] = {height};
                } else {
                    let th = 0;
                    for (let i = 0; i < len; i++) {
                        const cId = children[i].id;
                        const child_size = sizes[cId];
                        th += child_size.height;
                        if (i < len - 1) th += spacing.y;
                    }
                    th = Math.max(th, height);
                    sizes[id] = {height: th};
                }
                idx++;
                if (idx < infos.length) getSize(idx);
            }
            getSize(0);

            this.maxHeight = sizes[this.chatList[0].id].height + 200;
            const {width} = this.getBounds(this.chatList[0].id);
            this.maxWidth = (this.chatList[this.chatList.length - 1].stage + 1) * (width + spacing.x) + 200 - spacing.x;

            const getGlobalPosition = (node) => {
                const {x, y, width, height} = this.getBounds(node.id);
                if (!node.parentId) {
                    const {id} = node;
                    position[id] = {x, y};
                }
                const sortedChildren = node.children.sort((a, b) => {
                    return a.index - b.index;
                });

                const parentPos = position[node.id];

                const getHeight = (nodes) => {
                    const len = nodes.length;
                    let th = 0;
                    for (let i = 0; i < len; i++) {
                        const childNode = nodes[i];
                        const {id} = childNode;
                        const size = sizes[id];
                        th += size.height;
                        if (i < len - 1) th += spacing.y;
                    }
                    return th;
                };
                if (sortedChildren.length > 0) {
                    const rh = getHeight(sortedChildren);
                    const tx = parentPos.x + width + spacing.x;
                    let ty = parentPos.y + (height - rh) * 0.5;
                    for (let i = 0; i < sortedChildren.length; i++) {
                        const childNode = sortedChildren[i];
                        const {id} = childNode;
                        const size = sizes[id];
                        const childLen = childNode.children.length;
                        if (childLen > 0) {
                            const {height: ch} = this.getBounds(childNode.id);
                            position[id] = {x: tx, y: ty + (size.height - ch) * 0.5};
                        } else {
                            position[id] = {x: tx, y: ty};
                        }
                        ty += size.height;
                        if (i < sortedChildren.length - 1) {
                            ty += spacing.y;
                        }
                        getGlobalPosition(childNode);
                    }
                }
            }
            getGlobalPosition(this.chatList[0]);
            return position;
        },

        clickRightMenu(parentId) {
            this.isRightMenu = true;
            let currentNode = this.$refs['nodeRef' + parentId][0];
            this.addNodeCopilot(currentNode);
        },

        clickMenu(currentNode, item) {
            this.isShowDrawer = false;
            this.isRightMenu = false;
            switch (item.cmd) {
                case 'manual':
                    this.addNodeManual(currentNode);
                    break;
                case 'copilot':
                    this.addNodeCopilot(currentNode);
                    break;
                case 'delete':
                    this.deleteNode(currentNode);
                    break;
                case 'update':
                    this.updateNode(currentNode);
                    break;
            }
        }
    }
}
</script>

<style scoped>
</style>
