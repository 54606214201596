<template>
    <div ref="inputDiv" class="text-input text-base w-full whitespace-pre-line mt-2 border-0 leading-8"
         :class="textColor"
         @input="input"
         @keyup="keyup"
         @focus="focus"
         @blur="blur"
         @paste="handlePaste"
         @mousedown="focusInput"
         v-html="value||placeholder"
         contenteditable="true"></div>
</template>

<script>

export default {
    name: "ChatNodeInput",
    props: {
        content: {
            default() {
                return "";
            }
        },
        placeholder: {
            default() {
                return "请输入";
            }
        },
    },
    data() {
        return {
            isEditing: false,
            isDelete: true,
            value: "",
        }
    },
    computed: {
        textColor() {
           return  !this.content || this.content.length === 0 ? 'text-gray-400' : 'text-black'
        }
    },
    watch: {
        content() {
            if (!this.isEditing) {
                this.value = this.content;
            }
        },
    },
    mounted() {
        this.value = this.content;
    },
    methods: {
        input(event) {
            this.isEditing = true;
            this.$emit("inputValue", event.target.innerText);
        },
        focusInput() {
            this.$refs.inputDiv.focus();
        },
        focus() {
            this.$nextTick(()=>{
                if (!this.content || this.content.length === 0) {
                    this.$refs.inputDiv.innerHTML = "";
                }
            })
        },
        handlePaste(e) {
            e.preventDefault();
            const strip_html = (html)=> { //获得纯文本
                let tmp = document.createElement('DIV');
                tmp.innerHTML = html;
                html = tmp.textContent || tmp.innerText;
                html = html.replace(/(?:(\r\n)+|\r+|\n+)/g, '');
                return html;
            }
            let text = strip_html(e.clipboardData.getData("text/plain"));
            text = text.replace(/\r\n/gi, '').replace(/\n/gi, '');
            document.execCommand('insertHTML', false, text);
        },
        blur() {
            this.isEditing = false;
            this.$emit("blur");
            this.$nextTick(()=>{
                if (!this.content || this.content.length === 0) {
                    this.$refs.inputDiv.innerHTML = this.placeholder;
                }
            })
        },
        keyup(event) {
            if (event.keyCode === 8 && this.content.length === 0) {
                if (this.isDelete) {
                    this.isDelete = false;
                    this.$emit("deleteItem");
                } else {
                    this.isDelete = true;
                }
            } else {
                this.isDelete = false;
            }
        },
    }
}
</script>

<style scoped>
.text-input {

}

.text-input:focus {
    outline: none;
}
</style>
