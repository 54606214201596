<template>
    <chat-tree-template ref="scamperTreeRef">
        <template v-slot:default="slotProps">
            <scamper-node :ref="'nodeRef'+ slotProps.node.id" :node="slotProps.node" :key="slotProps.node.id"
                          @goTop="goTop"
                          @deleteNode="deleteNode"
                          @updateContent="updateContent"
                          @clickMoreMenu="clickMoreMenu" @clickDivMenu="clickDivMenu" @setExample="setExample"/>
        </template>
    </chat-tree-template>
</template>

<script>
import ChatTreeTemplate from "@/views/chat/component/ChatTreeTemplate.vue";
import ScamperNode from "@/views/chat/component/ScamperNode.vue";
import {nanoid} from "nanoid";
import {askScamper} from "@/api/ai";
import Broadcaster from "@/utils/Broadcaster";
import {getLocalUserInfo} from "@/utils/auth";
import {getChatList, saveChat, saveTopic} from "@/api/chat";
import ToastManager from "@/utils/ToastManager";

export default {
    name: "ScamperTree",
    components: {ScamperNode, ChatTreeTemplate},
    data() {
        return {
            topicId: this.$route.params.topicId,
            chatList: [],
            set: new Set(),
            examples: [
                [
                    "设计一款新的聊天应用",
                    "目标用户是18-30岁的年轻人\n" +
                    "主要用于社交聊天和娱乐\n" +
                    "现有同类应用功能单一,缺乏趣味性",
                    "用户粘性不高,容易离开转用其他应用\n" +
                    "缺乏有效社交场景和娱乐功能留住用户",
                    "提供丰富的社交和娱乐功能\n" +
                    "能让用户流连忘返,提高用户粘性",
                ],
                [
                    "利用大语言模型提升内容营销团队工作效率",
                    "内容营销团队人员数量有限\n" +
                    "内容创作周期长,质量不稳定\n" +
                    "希望利用AI生成内容提效",
                    "编辑人员创作能力参差不齐\n" +
                    "存在产能瓶颈,无法应对大量内容需求",
                    "通过大语言模型提高内容产出质量和速度\n" +
                    "通过引入大语言模型内容缩小团队规模，同时提升团队整体能力",
                ],
                [
                    "如何优化现有的前后端开发流程",
                    "开发团队采用前后端分离模式\n" +
                    "前端使用Vue,后端使用Node.js\n" +
                    "现有开发流程效率不高,前后端交付周期长",
                    "前后端rollback困难\n" +
                    "代码review效率低下\n" +
                    "测试覆盖率不足",
                    "减少不必要的会议沟通\n" +
                    "自动化和优化开发流程\n" +
                    "提高开发质量和交付速度",
                ],

            ]

        }
    },

    mounted() {
        Broadcaster.$on("refreshChat", () => {
            this.refresh();
        })
        this.init();
    },
    beforeDestroy() {
        Broadcaster.$off("refreshChat")
    },

    methods: {
        async getTopicId(content) {
            if (this.topicId) return this.topicId;

            const userInfo = getLocalUserInfo();
            const friendId = this.$route.params.friendId;

            const res = await saveTopic({
                name: content ? content : "未命名",
                userId: userInfo.id,
                friendId: friendId,
            })
            if (res.code === 0) {
                this.topicId = res.data;
                return this.topicId;
            }
        },

        refresh() {
            this.$nextTick(() => {
                if (this.$route.params.topicId) {
                    this.$router.push({
                        path: `/chat/view/${this.$route.params.friendId}`
                    })
                } else {
                    this.reset();
                    this.$refs.scamperTreeRef.resetTree(this.chatList);
                }
            })
        },

        init() {
            this.$nextTick(async () => {
                if (this.$route.params.topicId) {
                    const res = await getChatList({
                        topicId: this.$route.params.topicId,
                        size: 2000,
                        current: 1,
                    })
                    if (res.code === 0) {
                        this.initTree(res.data.records);
                    } else {
                        ToastManager.showError("获取对话失败，请刷新重试！")
                    }

                } else {
                    this.reset();
                }

                this.$refs.scamperTreeRef.resetTree(this.chatList);
            })
        },
        initTree(chats) {
            let map = new Map();
            chats.forEach((chat) => {
                let tmp = {
                    children: [],
                    ...chat,
                }
                map.set(chat.id, tmp)
            })

            chats.forEach((chat) => {
                if (chat.parentId) {
                    if (map.get(chat.parentId)) {
                        let parent = map.get(chat.parentId);
                        parent.children.push(map.get(chat.id));
                    }
                } else {
                    this.chatList.push({
                        id: nanoid(),
                        parentId: null,
                        nodeId: chat.id,
                        stage: 0,
                        content:chat.content,
                        index: 0,
                        x: 0,
                        y: 0,
                        children: [],
                    },);
                }
            })
            const insertChildren = (current, children, stage) => {
                children.forEach((child) => {
                    let node = this.createNode({
                        parentId: current.id,
                        index: child.sortKey,
                        nodeId: child.id,
                        content:child.content,
                        stage: stage,
                        x: 0,
                        y: 0,
                    })
                    current.children.push(node);
                    this.chatList.push(node);
                    if (child.children.length > 0) {
                        insertChildren(node, child.children, stage + 1);
                    }
                })
            }
            insertChildren(this.chatList[0], map.get(this.chatList[0].nodeId).children, 1);

            console.log("init tree", this.chatList);
        },

        createNode({parentId, stage, x, y, content, index,nodeId}) {
            return {
                parentId: parentId,
                id: nanoid(),
                nodeId:nodeId,
                stage: stage,
                index: index,
                content: content,
                x: x,
                y: y,
                children: [],
            }
        },

        async addNodeManual(parentNode, content) {
            console.log("add node manual", parentNode);
            if (!parentNode.nodeId) {
                await this.createChat(this.$refs['nodeRef'+parentNode.id]);
            }

            let node = this.createNode({
                parentId: parentNode.id,
                stage: parentNode.stage + 1,
                index: parentNode.children.length,
                content: content,
            })
            this.$refs.scamperTreeRef.addNodeManual(node,async ()=>{
                if (parentNode.nodeId) {
                    await this.createChat(this.$refs['nodeRef' + node.id]);
                }
            });
        },

        async clickDivMenu(params) {
            console.log("form", this.$refs['nodeRef' + params.node.id].form)
            let form = []

            for (let i = 0; i < 4; ++i) {
                let tmp = this.$refs['nodeRef' + params.node.id].form[i].answer;
                form.push(tmp);
            }

            const controller = new AbortController();
            Broadcaster.$emit("showThinkingView", () => {
                controller.abort();
            })
            const res = await askScamper({
                backgrounds: [],
                signal: controller.signal,
                form: form
            })
            Broadcaster.$emit("closeThinkingView")
            if (res.code === 0) {
                if (res.data && res.data.length > 0) {
                    res.data.forEach((answer) => {
                        this.addNodeManual(params.node, answer)
                    })
                }
            }
        },

        async clickMoreMenu(params) {

            let parentNode = this.$refs['nodeRef' + params.node.parentId];


            let form = []

            for (let i = 0; i < 4; ++i) {
                let tmp = parentNode.form[i].answer;
                form.push(tmp);
            }

            let backgrounds = [];

            parentNode.node.children.forEach((node) => {
                backgrounds.push(this.$refs['nodeRef' + node.id].content);
            })


            const controller = new AbortController();
            Broadcaster.$emit("showThinkingView", () => {
                controller.abort();
            })
            const res = await askScamper({
                backgrounds: backgrounds,
                signal: controller.signal,
                form: form
            })
            Broadcaster.$emit("closeThinkingView")
            if (res.code === 0) {
                if (res.data && res.data.length > 0) {
                    res.data.forEach((answer) => {
                        this.addNodeManual(parentNode.node, answer)
                    })
                }
            }
        },


        deleteNode(params) {
            this.$refs.scamperTreeRef.deleteNode(params.node);
        },

        goTop(params) {
            this.$refs.scamperTreeRef.goTop(params.node);
        },

        setExample(params) {
            for (let i = 0; i < 4; ++i) {
                params.node.form[i].answer = this.examples[params.index][i];
            }
            this.updateContent(params.node);
        },


        reset() {
            this.topicId = null;
            this.chatList = [
                {
                    id: nanoid(),
                    stage: 0,
                    content: "",
                    index: 0,
                    x: 0,
                    y: 0,
                    nodeId:null,
                    children: [],
                },
            ]
        },


        updateContent(currentNode) {
            if (currentNode.node.nodeId) {
                this.updateChat(currentNode);
            } else {
                this.createChat(currentNode)
            }
        },

        async createChat(currentNode) {
            if (this.set.has(currentNode.node.id)) return;
            this.set.add(currentNode.node.id);
            let topicId = await this.getTopicId(currentNode.form[0].answer);
            let parentNode = this.$refs['nodeRef'+currentNode.node.parentId];
            let parentNodeId = parentNode ? parentNode.node.nodeId : null;
            const res = await saveChat({
                content:currentNode.node.stage === 0 ? JSON.stringify({
                    a1:currentNode.form[0].answer,
                    a2:currentNode.form[1].answer,
                    a3:currentNode.form[2].answer,
                    a4:currentNode.form[3].answer,
                }) : currentNode.content,
                topicId:topicId,
                direction:1,
                sortKey:currentNode.node.index,
                userId:getLocalUserInfo().id,
                friendId:this.$route.params.friendId,
                parentId:parentNodeId,
            })
            if (res.code === 0) {
                currentNode.node.nodeId = res.data;
                console.log("create successfully!");
            }
        },

        updateTopicName(name) {
            if (!name || name.length === 0) return;
            saveTopic({
                id: this.topicId,
                name: name,
            })
        },

        async updateChat(currentNode) {
            const res = await saveChat({
                content:currentNode.node.stage === 0 ? JSON.stringify({
                    a1:currentNode.form[0].answer,
                    a2:currentNode.form[1].answer,
                    a3:currentNode.form[2].answer,
                    a4:currentNode.form[3].answer,
                }) : currentNode.content,
                sortKey:currentNode.node.index,
                id:currentNode.node.nodeId,
            })
            if (res.code === 0) {
                console.log("update successfully!");
            }

            if (currentNode.node.stage === 0 && currentNode.form[0].answer && currentNode.form[0].answer.length > 0) {
                this.updateTopicName(currentNode.form[0].answer);
            }
        },


    }

}
</script>

<style scoped>

</style>
