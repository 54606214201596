<template>
    <div :class="[directionClass]"
            class="fixed w-screen lg:w-96 h-full left-0 top-0 flex flex-col bg-gray-500/50 z-50" v-show="computedValue">
        <slot/>
    </div>
</template>

<script>
export default {
    name: "BaseDrawer",
    props: {
        value: {

        },
        direction: {

        }
    },
    computed: {

        directionClass() {
            switch (this.direction) {
                case 'btt': return 'justify-end';
                case 'ttb': return 'justify-start';
                case 'ltr': return 'items-start';
                case 'rtl': return 'items-end';
            }
            return "";
        },


        computedValue: {
            set(value) {
                this.$emit("update:value",value);
            },
            get() {
                return this.value;
            }
        }
    },

    watch: {
        // value() {
        //     if (this.value === false) {
        //         this.closeDrawer();
        //     }
        // }
    },

    data() {
        return {
            isShowDrawer:false,
        }
    },
    methods: {
        showDrawer() {
            this.computedValue = true;
        },
        closeDrawer() {
            this.computedValue = false;
        }
    },
}
</script>

<style scoped>

</style>
