import { render, staticRenderFns } from "./FriendItem.vue?vue&type=template&id=d43e80e6&scoped=true&"
import script from "./FriendItem.vue?vue&type=script&lang=js&"
export * from "./FriendItem.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d43e80e6",
  null
  
)

export default component.exports