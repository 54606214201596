import request from "@/utils/request";

export function payment({amountMoney,type}) {
    return request({
        url: '/okr-copilot-account-service/account/web/payment',
        method: 'get',
        params: {
            amountMoney,type,
        }
    })
}

export function orderStatusQuery({id}) {
    return request({
        url: '/okr-copilot-account-service/account/web/orderStatusQuery',
        method: 'get',
        params: {
            id,
        }
    })
}
