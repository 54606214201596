<template>
    <div
            class="w-96 h-fit md:w-96 flex flex-col absolute z-10"
            v-clickoutside="unselect" @pointerdown="pointerdown">
        <div
                :class="[isSelect ? 'border-blue-700' : 'border-white']"
                class="w-full h-fit rounded-xl shadow bg-white flex flex-col p-5 cursor-pointer border border-solid "
                @click="select">
            <div class="text-xl lg:text-base font-bold text-black"
                 @click="editAnswer" :contenteditable="node.questionContenteditable">{{ node.question }}
            </div>

            <!--        <div class="w-full flex flex-row" v-for="(item,index) in computedValues" :key="computedValues[index].key" >-->
            <!--&lt;!&ndash;            <div class="text-base w-fit mr-2 mt-2" v-if="node.stage !== 0">{{index+1}}.</div>&ndash;&gt;-->
            <!--                <chat-node-input-->
            <!--                    :ref="'inputRef'+ computedValues[index].key"-->
            <!--                    :content="computedValues[index].answer"-->
            <!--                    :placeholder="computedValues[index].placeholder"-->
            <!--                    @inputValue="(value)=>{inputValue(value,index)}"-->
            <!--                    @deleteItem="deleteItem(index)"-->
            <!--                />-->
            <!--        </div>-->
            <chat-node-input
                    :content="computedValue.answer"
                    :placeholder="computedValue.placeholder"
                    :key="computedValue.key"
                    @inputValue="inputValue" @blur="updateContent"
            />

            <!--        <div class="flex flex-row h-fit w-full cursor-pointer mt-2" v-if="node.isMore" @click="addValue">-->
            <!--            <base-icon :icon="mdiPlus" :icon-size="16" current-color="blue"/>-->
            <!--            <div class="text-sm text-blue-700">添加</div>-->
            <!--        </div>-->
        </div>

        <div class="absolute -top-12 left-0 w-full h-10  flex flex-row items-center justify-center"
             v-if="isSelect && node.stage !== 0">
            <div class="flex flex-row w-fit bg-white p-1 rounded shadow">
                <base-button tips="删除" :icon-size="20" :icon="mdiDelete" @click.native.stop="deleteNode"/>
                <base-button tips="置顶" :icon-size="20" :icon="mdiArrowUpBold" @click.native.stop="goTop"/>
            </div>
        </div>

        <div class="absolute -bottom-10 h-10 w-full flex flex-col items-center justify-center"
             v-show="isSelect && node.stage === 1">
            <base-button
                    style="transform: rotate(90deg);"
                    @click.native.stop="clickRightMenu" tips="Copilot拆解更多元素"
                    class="shadow" :icon-size="20" :icon="mdiSourceBranch"></base-button>
        </div>

        <div class="-right-10 w-10 h-full flex-row items-center justify-center hidden lg:flex absolute">
            <el-popover trigger="hover" v-model="isShowPopover">
                <div class="flex-col flex">
                    <div class="text-xs text-gray-500 px-1">{{ node.menusTitle }}</div>
                    <div class="text-sm text-black mt-2 hover:bg-gray-200 p-1 rounded cursor-pointer"
                         v-for="(item) in node.menus" :key="item" @click.stop="clickMenu(item)">{{ item.name }}
                    </div>
                </div>
                <base-button
                        slot="reference" class="shadow" :icon-size="20" :icon="mdiPlus"
                        v-show="isSelect && node.stage < 6"/>
            </el-popover>
        </div>
        <div class="absolute -right-10  w-10 h-full flex-row items-center justify-center flex lg:hidden">
            <base-button class="shadow" :icon-size="20" :icon="mdiPlus" v-show="isSelect && node.stage < 6"
                         @click.native.stop="showDrawer"/>
        </div>
    </div>
</template>

<script>
import {mdiPlus, mdiDelete, mdiSourceBranch, mdiArrowUpBold} from "@mdi/js"
import BaseButton from "@/components/button/BaseButton.vue";
import {nanoid} from "nanoid";
import ChatNodeInput from "@/views/chat/component/ChatNodeInput.vue";

export default {
    name: "ChatNode",
    components: {ChatNodeInput, BaseButton},
    props: {
        node: {},
    },
    data() {
        return {
            mdiPlus: mdiPlus,
            mdiDelete: mdiDelete,
            mdiSourceBranch: mdiSourceBranch,
            mdiArrowUpBold: mdiArrowUpBold,
            isSelect: false,
            isShowDrawer: false,
            isShowPopover: false,
            deleteIndex: -1,
            computedValue:
                {
                    answer: "",
                    placeholder: "",
                    key: nanoid(),
                }
        }
    },
    watch: {
        node() {
            this.update();
        }
    },
    mounted() {
        this.update();
    },
    methods: {
        pointerdown(event) {
            event.stopPropagation();
        },
        select() {
            this.isSelect = true;
        },

        update() {
            this.computedValue = {
                answer: this.node.answer,
                placeholder: this.node.placeholder,
                key: nanoid(),
            }
        },
        inputValue(value) {
            this.computedValue.answer = value;

        },

        updateContent() {
            this.$emit("updateContent",this);
        },

        unselect() {
            this.isSelect = false;
        },

        deleteNode() {
            this.$emit('deleteNode');
        },

        goTop() {
            this.$emit('goTop');
        },


        clickMenu(item) {
            this.isShowPopover = false;
            this.isSelect = false;
            this.$emit("clickMenu", this, item);
        },
        clickRightMenu(item) {
            this.isSelect = false;
            this.$emit("clickRightMenu", this.node.parentId, item);
        },
        editQuestion() {
            this.isSelect = true;
        },

        editAnswer() {
            this.isSelect = true;
        },

        showDrawer() {
            this.$emit("showDrawer", this)
        },
    }
}
</script>

<style scoped>
/deep/ .el-textarea__inner {
    border: none !important;
    padding: 0 !important;
    line-height: 20px;
    font-size: 14px;
    height: 20px;
    resize: none;
}


</style>
