import {getToken} from "@/utils/auth";
import {getTmpFriendIdByName} from "@/utils/mock";


export async function askFriend({friendName, question}) {
    const url = "https://okr-bot-server-web-framework-wafuimczis.eu-west-1.fcapp.run/v1/friend";
    const token = getToken();

    const response = await fetch(url,{
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
        },
        body: JSON.stringify({question,friendId:getTmpFriendIdByName(friendName),token}),
    })
    return await response.json();
}

export async function chat({history,signal}) {
    const url = "https://okr-bot-server-web-framework-wafuimczis.eu-west-1.fcapp.run/v1/chat";
    const token = getToken();

    const response = await fetch(url,{
        method: "POST",
        signal:signal,
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
        },
        body: JSON.stringify({history,token}),
    })
    return await response.json();
}


export async function getRecQuestion({answer, question}) {
    const url = "https://okr-bot-server-web-framework-wafuimczis.eu-west-1.fcapp.run/v1/recQuestion";
    const token = getToken();

    const response = await fetch(url,{
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
        },
        body: JSON.stringify({question,answer,token}),
    })
    return await response.json();
}


export async function askCopilot({stage, objective, backgrounds, signal}) {
    const url = "https://complete-okr-copt-server-gbgatxngsp.eu-west-1.fcapp.run/v1/copilot";
    const token = getToken();

    const response = await fetch(url,{
        method: "POST",
        signal: signal,
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
        },
        body: JSON.stringify({stage, objective, backgrounds,token}),
    })
    return await response.json();
}

export async function askScamper({ form, backgrounds, signal}) {
    const url = "https://complete-okr-copt-server-gbgatxngsp.eu-west-1.fcapp.run/v1/scamper";
    const token = getToken();

    const response = await fetch(url,{
        method: "POST",
        signal: signal,
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
        },
        body: JSON.stringify({form, backgrounds,token}),
    })
    return await response.json();
}
