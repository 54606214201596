<template>
    <div class="w-full h-full flex flex-col">
        <div class="w-full flex flex-row items-center bg-white px-5 z-20" style="height: 70px !important; min-height: 70px !important;">
            <base-button :icon-size="20" tips="返回" :icon="mdiKeyboardBackspace" @click.native.stop="goBack"/>
            <div class="text-2xl text-black font-bold ml-5">管理知识库</div>
            <div class="flex-1"/>
            <el-popover trigger="click" v-model="isShowPopover">
                <div>
                    <add-document-way v-for="(item) in ways"
                                      @click.native.stop="showAddDialog(item.type)"
                                      :key="item.type" :icon="item.icon" :name="item.name"/>
                </div>
                <base-button slot="reference" :icon-size="20" :icon="mdiPlus" color="info" label="添加数据"></base-button>
            </el-popover>
        </div>
      <router-view />
        <add-document-dialog ref="addDialogRef"/>
    </div>
</template>

<script>


import BaseButton from "@/components/button/BaseButton.vue";
import {mdiKeyboardBackspace, mdiPlus,mdiText,mdiLinkVariant,mdiFileDocumentOutline} from "@mdi/js";
import AddDocumentDialog from "@/views/data/components/AddDocumentDialog.vue";
import AddDocumentWay from "@/views/data/components/AddDocumentWay.vue";

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "index",
    components: {AddDocumentWay, AddDocumentDialog, BaseButton},
    data() {
        return {
            mdiKeyboardBackspace:mdiKeyboardBackspace,
            mdiPlus:mdiPlus,
            isShowPopover:false,
            ways: [
                {
                    name:"文本",
                    type:"content",
                    icon:mdiText,
                },
                {
                    name:"文件",
                    type:"file",
                    icon:mdiFileDocumentOutline,
                },
                {
                    name:"网址",
                    type:"url",
                    icon:mdiLinkVariant,
                },
            ]
        }
    },
    methods: {
        goBack() {
            if (this.$route.params.topicId) {
                this.$router.push({
                    path:`/chat/view/${this.$route.params.friendId}/${this.$route.params.topicId}`,
                })
            } else {
                this.$router.push({
                    path:`/chat/view/${this.$route.params.friendId}`,
                })
            }
        },
        showAddDialog(type){
            this.isShowPopover = false;
            this.$refs.addDialogRef.showDialog(type);
        }
    }
}
</script>

<style scoped>

</style>
