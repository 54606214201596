<template>
    <div class="w-full h-fit border-solid border border-gray-100 rounded-xl shadow-xl">
        <div class="h-20 w-full flex flex-row items-center px-5 bg-gradient-to-tr  rounded-t-xl from-purple-100 via-pink-200 to-red-200"/>
        <avatar class="-mt-5 ml-5" :size="10" :user="figure"/>
        <div class="ml-5 mt-5 text-base font-bold">{{ figure.name }}</div>
        <label-items class="ml-5 mt-5" :label="figure.label"/>
        <div class="mx-5 mt-5 whitespace-pre-line text-xs sm:text-base text-gray-500 flex-1">
            {{ figure.description }}
        </div>

        <div class="w-full h-10 flex flex-row-reverse pr-5  mt-5 mb-5">
            <base-button label="添加" class="mr-4" type="submit" color="info" @click.native.stop="submit"/>
        </div>
    </div>
</template>

<script>
import Avatar from "@/components/avatar.vue";
import LabelItems from "@/views/space/components/LabelItems.vue";
import BaseButton from "@/components/button/BaseButton.vue";
import {getLocalUserInfo} from "@/utils/auth";
import {saveFriend} from "@/api/chat";

export default {
    name: "FigureInfoCard",
    components: {BaseButton, LabelItems, Avatar},
    props: {
        figure: {

        }
    },
    methods: {
        async submit() {
            const userInfo = getLocalUserInfo();
            const res = await saveFriend({
                figureId:this.figure.id,
                userId:userInfo.id,
            })
            if(res.code === 0) {
                this.$router.push({name: "chatView", params: {friendId: res.data.toString()}});
                this.$emit("submit")
            }
        }
    }
}
</script>

<style scoped>

</style>
