import Vue from "vue";
import Router from 'vue-router';
import copilotRouter from "@/router/modules/copilot";
import Layout from "@/layout/index.vue";
import chat from "@/views/chat/index.vue";
import chatView from "@/views/chat/chatView.vue";
import data from "@/views/data/index.vue";
import dataView from "@/views/data/dataView.vue";
import space from "@/views/space/index.vue";
import account from "@/views/account/index.vue";

Vue.use(Router)

export const constantRoutes = [
    {
        path: '/',
        component: Layout,
        hidden: true,
        redirect:'/chat',
        children:[
            {
                path: '/chat',
                component: chat,
                name:'chat',
                children: [
                    {
                        path: '/chat/view/:friendId/:topicId?',
                        component: chatView,
                        name:'chatView',
                    },
                ]
            },
            {
                path: '/data',
                component: data,
                name:'data',
                children: [
                    {
                        path: '/data/chat/:friendId/:topicId?',
                        component: dataView,
                        name:'dataView',
                    },
                ]
            },
            {
                path: '/space',
                component: space,
                name:'space',
                children: [

                ],
            },
            {
                path: '/account',
                component: account,
                name:'account',
                children: [

                ],
            }
        ]
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/login/index'),
        hidden: true,
    },
];

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */
export const asyncRoutes = [
    /** when your routing map is too long, you can split it into small modules **/
    copilotRouter,
    // 404 page must be placed at the end !!!
    {path: '*', redirect: '/', hidden: true},
];

const createRouter = () => new Router({
    // mode: 'history', // require service support
    scrollBehavior: () => ({y: 0}),
    routes: constantRoutes,
});

const router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
    const newRouter = createRouter();
    router.matcher = newRouter.matcher; // reset router
}

export default router;
