<template>
    <div
        :class="[isSelected ? 'rounded-lg border border-slate-100 border-solid bg-white' : '']"
        class="w-12 h-12 m-2 flex flex-row items-center justify-center cursor-pointer">
        <base-icon :icon="icon"/>
    </div>
</template>

<script>
import BaseIcon from "@/components/button/BaseIcon.vue";

export default {
    name: "AppItem",
    components: {BaseIcon},
    props: {
        icon: {

        },
        isSelected: {

        }
    }
}
</script>

<style scoped>

</style>
