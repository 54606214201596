<template>
  <div class="w-full p-3 flex"
       :class="[item.direction === 0 ? 'flex-row-reverse' : 'flex-row']" >
      <avatar :user="item.user" class="mx-2"/>
      <chat-text :text="item.content"  :is-self="item.isSelf" :enhance="item.enhance"/>
  </div>
</template>

<script>
import Avatar from "@/components/avatar.vue";
import ChatText from "@/views/chat/component/ChatText.vue";

export default {
    name: "ChatItem",
    components: {ChatText, Avatar},
    props: {
        item: {

        }
    }
}
</script>

<style scoped>

</style>
