<template>
  <base-button :icon="mdiKeyboardBackspace" :icon-size="20"/>
</template>

<script>
import BaseButton from "@/components/button/BaseButton.vue";
import {mdiKeyboardBackspace} from "@mdi/js";

export default {
    name: "BackButton",
    components: {BaseButton},
    data() {
        return {
            mdiKeyboardBackspace:mdiKeyboardBackspace
        }
    }
}
</script>

<style scoped>

</style>
