<template>
    <el-tooltip :content="tips" :disabled="!tips || isMobile">
    <component
            :is="is"
            :class="componentClass"
            :href="href"
            :type="computedType"
            :to="to"
            :target="target"
            :disabled="disabled"
    >
        <base-icon v-if="icon" :icon="icon" :icon-size="iconSize" />
        <span v-if="label" :class="labelClass">{{ label }}</span>
    </component>
    </el-tooltip>
</template>

<script>
import BaseIcon from "@/components/button/BaseIcon.vue";
import {getButtonColor} from "@/utils/color";
import {isMobile} from "@/utils/tools";

export default {
    name: "BaseButton",
    components: {BaseIcon},
    props: {
        icon: {},
        iconSize: {default:null},
        label:{},
        href:{},
        target:{},
        to:{},
        type:{},
        color:{default:"white"},
        as:{},
        small:{},
        outline: {default:false},
        active: {},
        disabled: {},
        roundedFull: {},
        tips:{}
    },
    computed: {
        labelClass() {
            return this.small && this.icon ? "px-1" : "px-2";
        },
        componentClass() {
            const base = [
                "inline-flex",
                "justify-center",
                "items-center",
                "whitespace-nowrap",
                "focus:outline-none",
                "transition-colors",
                "focus:ring",
                "duration-150",
                "border",
                this.disabled ? "cursor-not-allowed" : "cursor-pointer",
                this.roundedFull ? "rounded-full" : "rounded",
                getButtonColor(this.color, this.outline, !this.disabled, this.active),
            ];
            if (!this.label && this.icon) {
                base.push("p-1");
            } else if (this.small) {
                base.push("text-sm", this.roundedFull ? "px-3 py-1" : "p-1");
            } else {
                base.push("py-2", this.roundedFull ? "px-6" : "px-3");
            }

            if (this.disabled) {
                base.push(this.outline ? "opacity-50" : "opacity-70");
            }
            return base;
        },
        is() {
            if (this.as) {
                return this.as;
            }
            if (this.href) {
                return "a";
            }

            return "button";
        },
        computedType() {
            if (this.is === "button") {
                return this.type ?? "button";
            }
            return null;
        }

    },
    methods: {

    },
    data() {
        return {
            isMobile:isMobile(),
        }
    }
}
</script>

<style scoped>

</style>
