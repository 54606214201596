<template>
    <div class="w-full h-full  overflow-y-auto p-4 xl:p-10">
        <div class="w-full h-fit grid gap-4 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
            <figure-card v-for="(figure) in figureList" :figure="figure" :key="figure.id"
                         @click.native.stop="showFigureInfo(figure)"/>
        </div>

        <base-dialog ref="baseDialogRef" >
            <div slot="body" class="w-full lg:w-7/12 flex flex-col lg:h-fit lg:rounded-xl bg-white shadow-2xl overflow-y-auto"
                 v-if="isShowFigureInfo">
                <div class="h-28 w-full flex flex-row items-center px-5 bg-gradient-to-tr from-purple-100 via-pink-200 to-red-200 lg:rounded-t-xl"/>
                <avatar class="-mt-10 ml-10" :size="20" :user="currentFigure"/>
                <div class="ml-10 mt-5 text-xl font-bold">{{ currentFigure.name }}</div>
                <label-items class="ml-10 mt-5" :label="currentFigure.label"/>
                <div class="mx-10 mt-5 whitespace-pre-line text-base text-gray-500 flex-1">
                    {{ currentFigure.description }}
                </div>
                <div class="w-full h-10 flex flex-row-reverse pr-5  mt-20 mb-5">
                    <base-button label="取消" :outline="true" type="submit" color="info" @click.native.stop="close"/>
                    <base-button label="对话" class="mr-4" type="submit" color="info" @click.native.stop="submit"/>
                </div>
            </div>
        </base-dialog>
    </div>
</template>

<script>
import FigureCard from "@/views/space/components/FigureCard.vue";
import ToastManager from "@/utils/ToastManager";
import BaseButton from "@/components/button/BaseButton.vue";
import Avatar from "@/components/avatar.vue";
import LabelItems from "@/views/space/components/LabelItems.vue";
import {getFigureList, saveFriend} from "@/api/chat";
import {getLocalUserInfo} from "@/utils/auth";
import BaseDialog from "@/components/dialog/BaseDialog.vue";

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "index",
    components: {BaseDialog, LabelItems, Avatar, BaseButton, FigureCard},
    mounted() {
        this.init();
    },
    data() {
        return {
            figureList: [],
            isShowFigureInfo: false,
            currentFigure: {},
            current: 1,
            size: 20,
        }
    },
    methods: {
        init() {
            this.getFigures();
        },

        async getFigures() {
            const res = await getFigureList({
                current: this.current,
                size: this.size,
            })
            if (res.code === 0) {
                const userInfo = getLocalUserInfo();
                res.data.records.forEach((figure) => {
                    if (userInfo && userInfo.invitationCode) {
                        if (figure.invitationCode === userInfo.invitationCode || figure.isIgnoreCode) {
                            this.figureList.push(figure);
                        }
                    } else {
                        this.figureList.push(figure);
                    }

                })
            }
        },

        showFigureInfo(figure) {
            if (figure.upcoming) {
                ToastManager.showInfo("即将上线，敬请期待！")
            } else {
                this.currentFigure = {...figure};
                this.isShowFigureInfo = true;
                this.$refs.baseDialogRef.showDialog()
            }
        },
        close() {
            this.currentFigure = {};
            this.isShowFigureInfo = false;
            this.$refs.baseDialogRef.closeDialog()
        },

        async submit() {
            const userInfo = getLocalUserInfo();
            const res = await saveFriend({
                figureId:this.currentFigure.id,
                userId:userInfo.id,
            })
            if(res.code === 0) {
                this.$router.push({name: "chatView", params: {friendId: res.data.toString()}});
            }
        }
    }
}
</script>

<style scoped>

</style>
