<template>
    <div id="app" class="overflow-hidden bg-blue-50/10">
        <router-view />
    </div>
</template>

<script>

export default {
    name: 'App',
    components: {},
    data() {
        return {
            vh: 0,
        }
    },
    mounted() {
        this.setVH();
        window.addEventListener('resize', this.setVH);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.setVH);
    },
    methods: {
        setVH() {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        },
    },
}
</script>

<style>
#app {
    position: fixed;
    width: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}
</style>
