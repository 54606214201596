<template>
    <div
        :class="[figure.upcoming ? 'bg-gray-50' : 'bg-white shadow lg:hover:shadow-xl']"
        class="h-fit p-6  flex flex-col rounded-xl  transition-shadow duration-500  cursor-pointer">
        <div class="flex flex-row items-start">
            <avatar :user="figure" :size="20"/>
            <div class="flex flex-col ml-2 h-20">
                <div class="text-lg">{{figure.name}}</div>
                <div class="flex-1"/>
                <label-items :label="figure.label"/>
            </div>
        </div>
    </div>
</template>

<script>


import LabelItems from "@/views/space/components/LabelItems.vue";
import Avatar from "@/components/avatar.vue";

export default {
    name: "FigureCard",
    components: {Avatar, LabelItems},
    props: {
        figure: {

        }
    }
}
</script>

<style scoped>

</style>
