<template>
    <div class="payment-way" :style="isSelected ? selected : normal">
        <el-image :src="icon" class="payment-way-icon"></el-image>
        <div class="payment-way-name" style="margin-left: 10px">{{name}}</div>
    </div>
</template>

<script>
export default {
    name: "paymentWay",
    props: {
        icon: {
            type: String,
        },
        isSelected: {
            type: Boolean,
        },
        name : {
            type: String,
        },
    },
    data() {
        return {
            normal : {
                border: '1px solid #DBDBDB',
            },

            selected : {
                border: '1px solid #479EFF',
            }

        }
    },
}
</script>

<style scoped>
.payment-way {
    width: 160px;
    height: 48px;
    background: #FFFFFF;
    border-radius: 8px;
    border: 1px solid #DBDBDB;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.payment-way-icon {
    width: 24px;
    height: 24px;
}

.payment-way-name {
    font-size: 14px;
    color: #1D2441;
    line-height: 20px;
}

</style>
