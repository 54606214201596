import {Message} from "element-ui";

export default class ToastManager {
    static __show(message,type,showClose=true,duration= 2000) {
        Message({
            message,
            showClose,
            type,
            duration,
        })
    }

    static showSuccess(msg,showClose=true) {
        this.__show(msg,"success",showClose)
    }

    static showSuccess2(msg,showClose=true) {
        this.__show(msg,"success",showClose,0)
    }

    static showError(msg,showClose=true) {
        this.__show(msg,"error",showClose)
    }

    static showWarning(msg,showClose=true) {
        this.__show(msg,"warning",showClose)
    }

    static showInfo(msg,showClose=true) {
        this.__show(msg,"info",showClose)
    }
}
