<template>
<div class="flex absolute bg-transparent z-0 flex-row items-center justify-center"
     :style="{left:link.left+'px',top:link.top + 'px', width:link.width+'px', height:link.height+'px'}">
    <svg xmlns="http://www.w3.org/2000/svg" version="2.0">
        <path stroke="blue" stroke-width="2" fill="none" :d="link.path"/>
    </svg>
</div>
</template>

<script>
export default {
    name: "ChatLink",
    props: {
        link:{

        }
    },
    computed:{
    }

}
</script>

<style scoped>
svg{
    width: 100%;
    height: 100%;
    shape-rendering: crispEdges;
}
</style>
