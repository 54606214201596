<template>
    <div class="relative bottom-0 m-4 mt-3 pointer-events-auto">
    <textarea
            class="w-full max-h-60 p-1.5 pl-3 pr-10 resize-none border rounded-lg outline-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-40"
            rows="3"
            ref="textareaDom"
            v-model="messageContent"
            :placeholder="placeholder"
            @keydown="(event) => enterInput(event)"
            @compositionstart="composing = true"
            @compositionend="composing = false"
            @focus="handleFocus"
    ></textarea>
        <chat-send-button
                class="absolute bottom-2 sm:bottom-2 right-3"
                :disabled="talking || messageContent.length === 0"
                @click.native.stop="sendMessage"
        />
    </div>
</template>

<script>
import ChatSendButton from "@/views/chat/component/ChatSendButton.vue";
import {isMobile} from "@/utils/tools";

export default {
    name: "ChatInput",
    components: {ChatSendButton},
    props: {
        talking: {

        }
    },
    data() {
        return {
            composing: false,
            messageContent: "",
            placeholder: "输入消息，Enter发送，Shift+Enter换行",
        }
    },
    methods: {
        enterInput(event) {
            if (event.key === "Enter") {
                if (event.shiftKey) return;

                event.preventDefault();

                if (this.composing) return;
                if (this.talking) return;

                // 发送消息
                this.sendMessage();

                // 当设备为移动端时，发送后关闭键盘
                if (isMobile()) (event.target).blur();
            }
        },
        sendMessage() {
            this.$emit("sendMessage",this.messageContent);
            this.messageContent = "";
        },
        handleFocus() {
            this.$nextTick(()=>{
                window.setTimeout(() => {
                    const element = this.$refs.textareaDom;
                    const elementRect = element.getBoundingClientRect();
                    const absoluteElementTop = elementRect.top + window.pageYOffset;
                    const middlePosition = absoluteElementTop - (window.innerHeight / 2);
                    window.scrollTo({ top: middlePosition, behavior: 'smooth' });
                }, 500);
            })
        },
    }
}
</script>

<style scoped>

</style>
