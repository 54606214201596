<template>
  <span class="inline-flex justify-center items-center">
      <svg
              viewBox="0 0 24 24"
              :width="iconSize"
              :height="iconSize"
              class="inline-block"
      >
        <path :fill="currentColor" :d="icon" />
      </svg>
      <slot />
    </span>
</template>

<script>
export default {
    name: "BaseIcon",
    props: {
        icon: {

        },
        iconSize: {
            default:24,
        },
        currentColor: {
            default: "currentColor",
        }
    }
}
</script>

<style scoped>

</style>
