import axios from 'axios';
import JSON_BIG from 'json-bigint';
import {getToken,} from '@/utils/auth';
import ToastManager from "@/utils/ToastManager";



window._axiosPromiseArr = [];
// create an axios instance
const service = axios.create({
    baseURL: '/prod-api', // url = base url + request url
    // withCredentials: true, // send cookies when cross-domain requests
    timeout: 60000, // request timeout
    transformResponse: data => {
        try {
            return JSON_BIG.parse(data);
        } catch (err) {
            return JSON.parse(data);
        }
    },
});

// request interceptor
service.interceptors.request.use(
    config => {
        // do something before request is sent
        if (getToken()) {
            config.headers['Authorization'] = getToken();
            config.headers['Access-Control-Allow-Origin'] = '*';
        }
        // 每个请求都会 加入
        config.cancelToken = new axios.CancelToken(cancel => {
            window._axiosPromiseArr.push({
                cancel,
            });
        });
        return config;
    },
    error => {
        // do something with request error
        return Promise.reject(error);
    },
);

// response interceptor
service.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    response => {
        const res = response.data;
        // if the custom code is not 20000, it is judged as an error.
        if (res.code !== 0 && res.code !== 1002 && res.code !== -1 && res.code !==
            1001 && res.code !== 300) {
            // Message({
            //     message: res.msg,
            //     type: 'error',
            //     duration: 3 * 1000,
            // });
            return res;
        } else {
            return res;
        }
    },
    error => {
        if (!axios.isCancel(error)) {
            let message = '参数异常，请重试';
            if (error.response && error.response.data) {
                let data = error.response.data;
                if (data.msg) {
                    message = data.msg;
                }
            }
            ToastManager.showError(message);
            return Promise.reject(error);
        } else {
            return new Promise(() => {
            });
        }
    },
);

export default service;
