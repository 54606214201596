<template>
    <div class="w-full h-full flex flex-col p-5 lg:p-10 bg-gray-50 overflow-y-auto">
        <user-info-card />
        <service-card  class="mt-10"/>
        <auth-info-card class="mt-10"/>
    </div>
</template>

<script>
import UserInfoCard from "@/views/account/commponent/UserInfoCard.vue";
import AuthInfoCard from "@/views/account/commponent/AuthInfoCard.vue";
import ServiceCard from "@/views/account/commponent/ServiceCard.vue";

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "index",
    components: {ServiceCard, AuthInfoCard, UserInfoCard}
}
</script>

<style scoped>

</style>
