<template>
    <div class="w-full h-full">
        <chat-list v-if="isShowList" :currentFriend="currentFriend" :key="currentFriend.id"></chat-list>
        <chat-tree :current-component="currentComponent" v-else></chat-tree>
    </div>
</template>

<script>
import ChatList from "@/views/chat/component/ChatList.vue";
import ChatTree from "@/views/chat/component/ChatTree.vue";
import {getFriendInfo} from "@/api/chat";


export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "friend",
    components: {ChatTree, ChatList},
    data() {
        return {
            isShowList: false,
            currentComponent: "",
            currentFriend: {

            },
        }
    },
    mounted() {
        this.init();
    },
    watch: {
        $route() {
            this.init();
        }
    },

    methods: {
        async init() {
            const res = await getFriendInfo({
                friendId: this.$route.params.friendId
            })
            if (res.code === 0) {
                this.currentFriend = res.data;
                if (res.data.figureReq.type === 'tool') {
                    this.setComponent(res.data.figureReq);
                    this.isShowList = false;
                } else {
                    this.isShowList = true;
                }
            }
        },
        setComponent(figure) {
            if (figure.name === "SCAMPER创新法") {
                this.currentComponent = "ScamperTree";
            } else if (figure.name === "OKR Copilot") {
                this.currentComponent = "OkrCopilotTree";
            } else if (figure.name === "Chat Tree") {
                this.currentComponent = "ChatDeepTree";
            } else {
                this.currentComponent = "ChatTreeTemplate";
            }
        },


    },
}
</script>

<style scoped>

</style>
