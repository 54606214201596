<template>
<div class="w-fit p-2 rounded cursor-pointer border mr-5 mt-3">
    {{question}}
</div>
</template>

<script>
export default {
    name: "RecQuestion",
    props: {
        question: {

        }
    }
}
</script>

<style scoped>

</style>
