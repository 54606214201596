<template>
    <account-item-card title="个人资料">
        <div class="flex flex-row items-center lg:w-4/12 mb-4">
            <avatar :user="{name:name,avatar:avatar}" :size="24"/>
            <div class="flex-1"/>
            <base-button :outline="true" type="submit" label="上传头像" color="info" class="text-base ml-10"
                         @click.native.stop="showCropper"/>
        </div>
        <form-field label="姓名" class="lg:w-4/12 mb-10">
            <form-input v-model="name" :icon="mdiAccount" name="username"/>
        </form-field>
        <div class="flex flex-row items-center">
            <div class="flex-1 lg:hidden"/>
            <base-button type="submit" label="确定" color="info" class="text-base" :disabled="disabled"
                         @click.native.stop="setUserInfo"/>
            <base-button :outline="true" type="submit" label="取消" color="info" :disabled="disabled"
                         @click.native.stop="refresh"
                         class="text-base ml-10"/>
        </div>
        <base-dialog ref="baseDialogRef">
            <image-cropper slot="body" :value="imageCropperShow" :key="imageCropperKey" :width="300" :height="300"
                           :params="imageParams"
                           url="/okr-copilot-user-service/fileUpload/saveHeadImg" lang-type="zh" field="file"
                           :with-credentials="true"
                           @close="close"
                           @crop-upload-success="cropSuccess"/>
        </base-dialog>
    </account-item-card>
</template>

<script>
import FormInput from "@/components/form/FormInput.vue";
import {mdiAccount} from "@mdi/js";
import Avatar from "@/components/avatar.vue";
import BaseButton from "@/components/button/BaseButton.vue";
import FormField from "@/components/form/FormField.vue";
import {getUserInfo, setUserInfo} from "@/api/user";
import BaseDialog from "@/components/dialog/BaseDialog.vue";
import ImageCropper from "@/components/ImageCropper/index.vue";
import ToastManager from "@/utils/ToastManager";
import AccountItemCard from "@/views/account/commponent/AccountItemCard.vue";

export default {
    name: "UserInfoCard",
    components: {AccountItemCard, BaseDialog, FormField, BaseButton, ImageCropper, Avatar, FormInput},
    props: {},
    data() {
        return {
            imageCropperKey: '',
            imageParams: {},
            imageCropperShow: false,
            mdiAccount: mdiAccount,
            disabled: true,
            id: null,
            name: "",
            avatar: "",
            rawData: {
                id: null,
                name: "",
                avatar: "",
            },
        }
    },
    watch: {
        name() {
            if (this.name === this.rawData.name && this.avatar === this.rawData.avatar) {
                this.disabled = true;
            } else {
                this.disabled = false;
            }
        },
        avatar() {
            if (this.name === this.rawData.name && this.avatar === this.rawData.avatar) {
                this.disabled = true;
            } else {
                this.disabled = false;
            }
        }
    },
    mounted() {
        this.getUserInfo();
    },
    methods: {
        async getUserInfo() {
            const res = await getUserInfo();
            if (res && res.code === 0) {
                this.id = res.data.id;
                this.name = res.data.name;
                this.avatar = res.data.avatar;
                this.rawData = {...res.data};
            } else {
                ToastManager.showError("获取用户信息失败！");
            }
        },

        async setUserInfo() {
            const res = await setUserInfo({
                id: this.id,
                name: this.name,
                avatar: this.avatar,
            })
            if (res && res.code === 0) {
                this.rawData = {
                    id: this.id,
                    name: this.name,
                    avatar: this.avatar,
                }
                this.disabled = true;
                ToastManager.showSuccess("修改成功！")
            } else {
                ToastManager.showError("修改失败！")
            }
        },

        refresh() {
            this.id = this.rawData.id;
            this.name = this.rawData.name;
            this.avatar = this.rawData.avatar;
            this.disabled = true;
        },

        close() {
            this.imageCropperShow = false;
            this.$refs.baseDialogRef.closeDialog()
        },
        cropSuccess(resData) {
            if (resData) {
                this.avatar = resData;
                this.close();
            }
        },
        showCropper() {
            this.imageCropperShow = true;
            this.$refs.baseDialogRef.showDialog()
        }
    },
}
</script>

<style scoped>

</style>
