<template>
    <base-button :icon="mdiWindowClose" :icon-size="20" :disabled="disabled"/>
</template>

<script>
import BaseButton from "@/components/button/BaseButton.vue";
import {mdiWindowClose} from "@mdi/js";

export default {
    name: "CloseButton",
    components: {BaseButton},
    props: {
        disabled: {
            default:false,
        }
    },
    data() {
        return {
            mdiWindowClose:mdiWindowClose
        }
    }
}
</script>

<style scoped>

</style>
