import router from "@/router";
import {getToken, logout} from "@/utils/auth";

const whiteList = ["login"];

router.beforeEach( async (to,from, next)=>{
    let token = getToken();
    if (token) {
        if (to.name === 'login') {
            logout()
            next()
        } else {
            next();
        }
    } else {
        if (whiteList.indexOf(to.name) !== -1) {
            next();
        } else {
            next({path:'/login'});
            //next(`/login?redirect=${to.fullPath}`);
        }
    }
})
