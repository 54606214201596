<template>
    <div class="flex flex-col w-96 h-fit md:w-96" v-clickoutside="unselect" @pointerdown="pointerdown">
        <div :class="[isSelect ? 'border-blue-700' : 'border-white']"
                class="w-full h-fit rounded-xl shadow bg-white flex flex-col p-5 cursor-pointer border border-solid "
                @click="select">
            <chat-node-input :key="node.id" :content="content" v-if="node.stage !== 0" @inputValue="inputContent" @blur="updateContent"/>
            <div v-else>
                <div class="flex flex-col mt-1 mb-5" v-for="(item,index) in form" :key="item.question">
                    <div class="text-xl lg:text-base font-bold text-black">{{`${index+1}、` + item.question}}</div>
                    <chat-node-input  class="mt-2" :content="form[index].answer" :placeholder="item.placeholder" @blur="updateContent" @inputValue="(value)=>{inputValue(value,index)}"/>
                </div>

                <div class="flex flex-row mt-10">
                    <div class="flex-1"/>
                    <div class="pt-1 text-xs">示例：</div>
                    <div class="ml-2 text-xs rounded p-1 bg-red-100 cursor-pointer" @click="setExample(0)">产品设计</div>
                    <div class="ml-2 text-xs rounded p-1 bg-green-100 cursor-pointer" @click="setExample(1)">想法实现</div>
                    <div class="ml-2 text-xs rounded p-1 bg-blue-100 cursor-pointer" @click="setExample(2)">流程优化</div>
                </div>
            </div>
        </div>

        <div class="absolute -top-12 left-0 w-full h-10  flex flex-row items-center justify-center" v-if="isSelect && node.stage !== 0">
            <div class="flex flex-row w-fit bg-white p-1 rounded shadow">
                <base-button tips="删除" :icon-size="20" :icon="mdiDelete" @click.native.stop="deleteNode"/>
                <base-button tips="置顶" :icon-size="20" :icon="mdiArrowUpBold" @click.native.stop="goTop"/>
            </div>
        </div>

        <div class="absolute -bottom-10 h-10 w-full flex flex-col items-center justify-center" v-show="isSelect && node.stage === 1">
            <base-button tips="继续拆解"
                style="transform: rotate(90deg);"
                @click.native.stop="clickMoreMenu"
                class="shadow" :icon-size="20" :icon="mdiSourceBranch"></base-button>
        </div>


        <div class="absolute -right-10  w-10 h-full flex-row items-center justify-center flex ">
            <base-button tips="SCAMPER拆解" class="shadow" :icon-size="20" :icon="mdiPlus" v-show="isSelect && node.stage < 1" @click.native.stop="clickDivMenu"/>
        </div>
    </div>
</template>

<script>
import ChatNodeInput from "@/views/chat/component/ChatNodeInput.vue";
import BaseButton from "@/components/button/BaseButton.vue";
import {mdiArrowUpBold, mdiDelete, mdiPlus, mdiSourceBranch} from "@mdi/js";

export default {
    name: "ScamperNode",
    components: {BaseButton, ChatNodeInput},
    props: {
        node: {}
    },
    data() {
        return {
            mdiPlus:mdiPlus,
            mdiDelete:mdiDelete,
            mdiSourceBranch:mdiSourceBranch,
            mdiArrowUpBold:mdiArrowUpBold,
            isSelect: false,
            isShowDrawer:false,
            isShowPopover:false,
            content:"",
            form:[
                {
                  question: "您希望创新的问题、产品或想法的描述",
                  answer:"",
                  placeholder:"设计什么产品、解决什么问题",
                },
                {
                    question: "与该问题、产品或想法相关的详细背景信息",
                    answer:"",
                    placeholder:"例如：所处行业与领域、目标用户等",
                },
                {
                    question: "您目前存在的限制或挑战",
                    answer:"",
                    placeholder:"例如：时间、成本、技术、人力等",
                },
                {
                    question: "您希望达到的目标或期望的结果",
                    answer:"",
                    placeholder:"例如：业绩翻倍、成本降低、效率提升等",
                },
            ],
        }
    },
    mounted() {
        this.init();
    },
    watch: {
      node() {
          this.init();
      }
    },
    methods: {
        pointerdown(event) {
            event.stopPropagation();
        },
        init() {
            if (this.node.stage === 0) {
                try {
                    let {a1,a2,a3,a4} = JSON.parse(this.node.content);
                    this.form[0].answer = a1;
                    this.form[1].answer = a2;
                    this.form[2].answer = a3;
                    this.form[3].answer = a4;
                } catch (e) {
                    this.form[0].answer = "";
                    this.form[1].answer = "";
                    this.form[2].answer = "";
                    this.form[3].answer = "";
                }
            } else {
                this.content = this.node.content;
            }

        },
        select() {
            this.isSelect = true
        },
        unselect() {
            this.isSelect = false;
        },
        inputValue(value,index) {
            this.form[index].answer = value;
        },
        inputContent(value) {
            this.content = value;
        },
        clickMoreMenu() {
            this.$emit("clickMoreMenu",{node:this.node});
        },
        showDrawer() {

        },
        clickDivMenu() {
            this.$emit("clickDivMenu",{node:this.node});
        },
        deleteNode() {
            this.$emit("deleteNode",{node:this.node});
        },
        goTop() {
            this.$emit("goTop",{node:this.node});
        },
        setExample(index) {
            this.$emit("setExample",{node:this,index});
        },

        updateContent() {
          this.$emit("updateContent",this);
        },
    }
}
</script>

<style scoped>

</style>
