<template>
    <chat-tree-vertical-template ref="chatDeepTreeRef" >
        <template v-slot:default="slotProps">
            <chat-deep-node :ref="'nodeRef'+ slotProps.node.id" :node="slotProps.node" :key="slotProps.node.id"
                          @goTop="goTop"
                            @askAI="askAI"
                          @deleteNode="deleteNode"
                          @updateQuestion="updateContent"
                          @updateAnswer="updateContent"
                            @updatePosition="updatePosition"
                          @clickMoreMenu="clickMoreMenu"
                            @clickDivMenu="clickDivMenu" />
        </template>
    </chat-tree-vertical-template>
</template>

<script>
import ChatDeepNode from "@/views/chat/component/ChatDeepNode.vue";
import Broadcaster from "@/utils/Broadcaster";
import {nanoid} from "nanoid";
import {chat} from "@/api/ai";
import ToastManager from "@/utils/ToastManager";
import {getChatList, saveChat, saveTopic} from "@/api/chat";
import {getLocalUserInfo} from "@/utils/auth";
import ChatTreeVerticalTemplate from "@/views/chat/component/ChatTreeVerticalTemplate.vue";

export default {
    name: "ChatDeepTree",
    components: {ChatTreeVerticalTemplate, ChatDeepNode,},
    data() {
        return {
            chatList:[],
            set:new Set(),
            topicId:this.$route.params.topicId,
        }
    },

    mounted() {
        Broadcaster.$on("refreshChat", () => {
            this.refresh();
        })
        this.init();
    },
    beforeDestroy() {
        Broadcaster.$off("refreshChat")
    },

    methods: {

        refresh() {
            this.$nextTick(()=>{
                if (this.$route.params.topicId) {
                    this.$router.push({
                        path:`/chat/view/${this.$route.params.friendId}`
                    })
                } else {
                    this.reset();
                    this.$refs.chatDeepTreeRef.resetTree(this.chatList);
                }
            })
        },

        init() {
            this.$nextTick(async ()=>{
                if (this.$route.params.topicId) {
                    const res = await getChatList({
                        topicId:this.$route.params.topicId,
                        size:2000,
                        current:1,
                    })
                    if (res.code === 0) {
                        this.initTree(res.data.records);
                    } else {
                        ToastManager.showError("获取对话失败，请刷新重试！")
                    }

                } else {
                    this.reset();
                }

                this.$refs.chatDeepTreeRef.resetTree(this.chatList);
            })
        },

        initTree(chats) {
            let map = new Map();
            chats.forEach((chat)=>{
                let tmp = {
                    children:[],
                    ...chat,
                }
                map.set(chat.id,tmp)
            })

            chats.forEach((chat)=>{
                if (chat.parentId) {
                    if (map.get(chat.parentId)) {
                        let parent = map.get(chat.parentId);
                        parent.children.push(map.get(chat.id));
                    }
                } else {
                    let content = JSON.parse(chat.content);
                    this.chatList.push({
                        id:nanoid(),
                        parentId:null,
                        nodeId:chat.id,
                        stage:0,
                        question:content.question,
                        answer:content.answer,
                        index:0,
                        x:0,
                        y:0,
                        children:[

                        ],
                    },);
                }
            })
            const insertChildren = (current,children,stage) => {
                children.forEach((child)=>{
                    let content = JSON.parse(child.content);
                    let node = this.createNode({
                        parentId:current.id,
                        index:child.sortKey,
                        nodeId:child.id,
                        question:content.question,
                        answer:content.answer,
                        stage:stage,
                        x:0,
                        y:0,
                    })
                    current.children.push(node);
                    this.chatList.push(node);
                    if(child.children.length > 0) {
                        insertChildren(node,child.children,stage+1);
                    }
                })
            }
            insertChildren(this.chatList[0],map.get(this.chatList[0].nodeId).children,1);

            console.log("init tree",this.chatList);
        },

        createNode({parentId,stage,x,y,index,nodeId,question,answer}) {
            return {
                parentId: parentId,
                id: nanoid(),
                stage: stage,
                index: index,
                question:question,
                answer:answer,
                nodeId:nodeId,
                x: x,
                y: y,
                children: [],
            }
        },

        async addNodeManual(parentNode) {
            if (!parentNode.nodeId) {
              await this.createChat(this.$refs['nodeRef'+parentNode.id]);
            }

            let node = this.createNode({
                parentId:parentNode.id,
                stage:parentNode.stage+1,
                index:parentNode.children.length,
            })
            this.$refs.chatDeepTreeRef.addNodeManual(node,async ()=>{
                if (parentNode.nodeId) {
                    await this.createChat(this.$refs['nodeRef' + node.id]);
                }
            });
        },

         clickDivMenu(params) {
            this.addNodeManual(params.node);
        },

         clickMoreMenu(params) {
            let parentNode = this.$refs['nodeRef'+params.node.parentId];
            this.addNodeManual(parentNode.node);
        },

        async askAI(params) {
            if (params.that.question.length === 0) {
                ToastManager.showError("问题不能为空！");
                return;
            }
            let history = [params.that.question];
            let currentNode = params.that;
            while (currentNode.node.parentId) {
                currentNode = this.$refs['nodeRef'+currentNode.node.parentId];
                if (currentNode.answer.length > 0 && currentNode.question.length > 0) {
                    history.unshift(currentNode.answer);
                    history.unshift(currentNode.question);
                }
            }

            const controller = new AbortController();
            Broadcaster.$emit("showThinkingView", () => {
                controller.abort();
            })
            const res = await chat({
                history,
                signal:controller.signal,
            })
            Broadcaster.$emit("closeThinkingView")
            if (res.code === 0) {
                if (res.data) {
                    params.that.answer = res.data;
                    params.that.showAnswer();
                    await this.updateChat(params.that);
                }
            }

            this.updatePosition();
        },

        async getTopicId(content) {
            if (this.topicId) return this.topicId;

            const userInfo = getLocalUserInfo();
            const friendId = this.$route.params.friendId;

            const res = await saveTopic({
                name: content ? content :"未命名",
                userId: userInfo.id,
                friendId: friendId,
            })
            if (res.code === 0) {
                this.topicId = res.data;
                return this.topicId;
            }
        },


        updateContent(currentNode) {
            if (currentNode.node.nodeId) {
                this.updateChat(currentNode);
            } else {
                this.createChat(currentNode)
            }
        },


        async createChat(currentNode){
            if (this.set.has(currentNode.node.id)) return;
            this.set.add(currentNode.node.id);
            let topicId = await this.getTopicId(currentNode.question);
            let parentNode = this.$refs['nodeRef'+currentNode.node.parentId];
            let parentNodeId = parentNode ? parentNode.node.nodeId : null;
            console.log("parentNode ==== ",parentNode);
            const res = await saveChat({
                content:JSON.stringify({question:currentNode.question,answer:currentNode.answer}),
                topicId:topicId,
                direction:2,
                sortKey:currentNode.node.index,
                userId:getLocalUserInfo().id,
                friendId:this.$route.params.friendId,
                parentId:parentNodeId,
            })
            if (res.code === 0) {
                currentNode.node.nodeId = res.data;
                console.log("create successfully!");
            }
        },

        async updateChat(currentNode) {
            const res = await saveChat({
                id:currentNode.node.nodeId,
                content:JSON.stringify({question:currentNode.question,answer:currentNode.answer}),
                sortKey:currentNode.node.index,
            })
            if (res.code === 0) {
                console.log("update successfully!");
            }

            if (!currentNode.node.parentId ) {
                this.updateTopicName(currentNode.question);
            }
        },

        updateTopicName(name) {
            if (!name || name.length === 0) return;
            saveTopic({
                id: this.topicId,
                name: name,
            })
        },


        async deleteChat(currentNode) {
            const res = await saveChat({
                id:currentNode.node.nodeId,
                status:1,
            })
            if (res.code === 0) {
                console.log("update successfully!");
            }
        },



        deleteNode(params) {
            this.$refs.chatDeepTreeRef.deleteNode(params.node);
        },

        goTop(params) {
            this.$refs.chatDeepTreeRef.goTop(params.node);
        },
        updatePosition() {
            this.$refs.chatDeepTreeRef.updatePosition();
        },

        reset() {
            this.topicId = null;
            this.chatList = [
                {
                    id:nanoid(),
                    parentId:null,
                    nodeId:null,
                    stage:0,
                    question:"",
                    answer:"",
                    index:0,
                    x:0,
                    y:0,
                    children:[

                    ],
                },
            ]
        }

    }

}
</script>

<style scoped>

</style>
