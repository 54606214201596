import CryptoJS from "crypto-js"

const TokenKey = 'Admin-Token';
const UserInfoKey = 'User-Info';

export function getToken() {
    return localStorage.getItem(TokenKey)
}

export function saveToken(token) {
    return localStorage.setItem(TokenKey,token);
}

export function getLocalUserInfo() {
    try {
        let userInfo =  JSON.parse(localStorage.getItem(UserInfoKey))
        return userInfo;
    } catch (e) {
        return null;
    }
}

export function setLocalUserInfo(userInfo) {
    return localStorage.setItem(UserInfoKey,JSON.stringify(userInfo));
}

export function logout() {
    localStorage.clear();
}


export function getCryptoKey() {
    let token = {
        name: "copilot",
        method: "AES",
        key: "U2FsdGVkX1/JqDzP/Z9MJSjZhCIiGln2H5OqJ//I9N0=",
        timeout: 120,
    }
    return JSON.stringify(token);
}


export function encode(data) {
    let key = getCryptoKey();
    return CryptoJS.AES.encrypt(data, key).toString();
}

export function decode(data) {
    let key = getCryptoKey();
    let bytes =  CryptoJS.AES.decrypt(data, key);
    return bytes.toString(CryptoJS.enc.Utf8);
}

export function sha256(data) {
    return CryptoJS.SHA256(data).toString();
}
