<template>
    <account-item-card title="账户安全" v-if="!isTencentMeetingApp">
        <base-button type="submit" label="退出登录" color="danger" class="lg:w-4/12" @click.native.stop="logout"/>
    </account-item-card>
</template>

<script>

import {mdiAccount} from "@mdi/js";
import BaseButton from "@/components/button/BaseButton.vue";
import {logout} from "@/utils/auth";
import AccountItemCard from "@/views/account/commponent/AccountItemCard.vue";
import {isTencentMeetingApp} from "@/utils/tools";

export default {
    name: "AuthInfoCard",
    methods: {
        logout() {
            logout();
            this.$router.push("/login");
        }
     },
    components: {AccountItemCard, BaseButton},
    props: {

    },
    data() {
        return {
            mdiAccount:mdiAccount,
            isTencentMeetingApp:isTencentMeetingApp()
        }
    }
}
</script>

<style scoped>

</style>
