<template>
    <img
        :class="['w-'+size, 'h-'+size]"
        class="block rounded-md bg-blue-50"
        :src="user.avatar ? user.avatar : defaultAvatar"
        :alt="user.name"
    />
</template>

<script>
export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "avatar",
    props: {
        user: {

        },
        size: {
          default:10,
        }
    },
    data() {
        return {
            defaultAvatar:"https://img.gouhuotech.com/file/avatar/icon_wx_defalut_72.png",
        }
    }
}
</script>

<style scoped>

</style>
