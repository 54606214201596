<template>
    <div class="w-full h-full flex flex-col bg-gray-50">
        <list-header :enable-edit-database="currentFriend.figureReq.type === 'knowledge'"/>
        <div class="w-full h-full flex-1  overflow-y-auto" ref="scrollContainer">
            <chat-item v-for="(item,index) in chatList" :key="index" :item="item"/>
            <div
                    class="w-9/12 max-w-fit  lg:w-5/12 lg:max-w-fit flex flex-wrap ml-16 pl-2"
                    v-if="!isStream && recQuestions.length > 0 && currentFriend.figureReq.type !== 'knowledge'">
                <rec-question
                        @click.native="sendMessage(question)"
                        v-for="(question) in recQuestions" :key="question" :question="question"/>
            </div>
        </div>
        <chat-input @sendMessage="sendMessage" :talking="talking"/>
    </div>
</template>

<script>
import ChatInput from "@/views/chat/component/ChatInput.vue";
import ChatItem from "@/views/chat/component/ChatItem.vue";
import ListHeader from "@/views/chat/component/ListHeader.vue";
import {askFriend, getRecQuestion} from "@/api/ai";
import Broadcaster from "@/utils/Broadcaster";
import {getLocalUserInfo} from "@/utils/auth";
import RecQuestion from "@/views/chat/component/RecQuestion.vue";
import {getChatList, saveChat, saveTopic} from "@/api/chat";
import {chatWithKnowledge} from "@/api/knowledge";

export default {
    name: "ChatList",
    components: {RecQuestion, ListHeader, ChatItem, ChatInput},
    props: {
        currentFriend: {}
    },
    data() {
        return {
            chatList: [],
            recQuestions: [
                "什么是OKR",
                "如何制定OKR",
                "如何复盘OKR",
            ],
            talking: false,
            isStream: false,
            topicId: this.$route.params.topicId,
            current: 1,
            size: 100,
        }
    },
    mounted() {
        this.initData();
        Broadcaster.$on("refreshChat", () => {
            this.refresh();
        });
    },
    beforeDestroy() {
        Broadcaster.$off("refreshChat");
    },
    methods: {

        async getTopicId(content) {
            if (this.topicId) return this.topicId;

            const userInfo = getLocalUserInfo();
            const friendId = this.$route.params.friendId;

            const res = await saveTopic({
                name: content,
                userId: userInfo.id,
                friendId
            });
            if (res.code === 0) {
                this.topicId = res.data;
                return res.data;
            } else {
                return null;
            }
        },

        async saveChat(index, content) {
            const res = await saveChat({
                topicId: this.topicId,
                content: content,
                direction: this.chatList[index].direction,
                parentId: this.chatList[index - 1].id,
                userId: getLocalUserInfo().id,
                friendId: this.$route.params.friendId,
            })
            if (res.code === 0) {
                this.chatList[index].id = res.data;
                this.updateLastTopicId({topicId:this.topicId,friendId: this.$route.params.friendId,})
            }
        },


        updateLastTopicId({topicId, friendId}) {
            Broadcaster.$emit("updateLastTopicId",{topicId,friendId});
        },


        async sendMessage(question) {
            let topicId = await this.getTopicId(question)
            if (!topicId) return;

            this.talking = true;
            let userInfo = getLocalUserInfo()
            this.chatList.push(
                {
                    id: null,
                    user: {avatar: userInfo.avatar},
                    direction: 0,
                    content: question,
                    enhance: false,
                }
            )

            this.chatList.push(
                {
                    id: null,
                    user: {avatar: this.currentFriend.figureReq.avatar},
                    direction: 1,
                    content: "思考中...",
                    enhance: false,
                }
            )
            this.recQuestions = [];
            this.goBottom();
            const res = await this.askAI(question);
            if (res.code === 0) {
                this.chatList[this.chatList.length - 1].enhance = res.enhance;
                this.outputStream(this.chatList.length - 1, res.data, question)
                await this.saveChat(this.chatList.length - 2, question)
                await this.saveChat(this.chatList.length - 1, res.data);
            } else {
                //ToastManager.showError(res.msg);
                this.chatList[this.chatList.length - 1].content = res.msg;
                this.talking = false;
            }
            Broadcaster.$emit("updateBalance");
            this.goBottom();
        },


        async askAI(question) {
            if (this.currentFriend.figureReq.type === "knowledge") {
                return chatWithKnowledge({
                    friendId:this.$route.params.friendId,
                    content:question
                })
            } else {
                return await askFriend({question, friendName: this.currentFriend.figureReq.name})
            }
        },


        outputStream(index, answer, question) {
            this.isStream = true;
            if (!this.chatList[index].enhance) {
                this.getRecQuestion({answer, question});
            }
            let content = answer.split("");
            this.chatList[index].content = "";
            content.forEach((item, step) => {
                setTimeout(() => {
                    this.chatList[index].content += item;
                    this.goBottom();
                    if (step === content.length - 1) {
                        this.isStream = false;
                        this.talking = false;
                    }
                }, step * 30)
            })
        },


        async getRecQuestion({answer, question}) {
            if (this.currentFriend.figureReq.type === 'knowledge') return;
            const response = await getRecQuestion({question, answer});
            if (response.code === 0 && response.data && response.data.length > 0) {
                this.recQuestions = [
                    ...response.data
                ]
            }
        },


        goBottom() {
            this.$nextTick(() => {
                const container = this.$refs.scrollContainer;
                container.scrollTop = container.scrollHeight;
            })
        },

        async refresh() {
            if (this.$route.params.topicId) {
                this.$router.push({
                    path:`/chat/view/${this.$route.params.friendId}`
                })
            } else {
                this.current = 1;
                this.size = 100;
                this.topicId = null;
                this.chatList = [{
                    user: {avatar: this.currentFriend.figureReq.avatar},
                    direction: 1,
                    content: this.currentFriend.figureReq.welcome,
                    enhance: false,
                }]
            }
        },

        async initData() {
            this.current = 1;
            this.size = 100;
            this.chatList = [{
                user: {avatar: this.currentFriend.figureReq.avatar},
                direction: 1,
                content: this.currentFriend.figureReq.welcome,
                enhance: false,
            }]

            if (this.$route.params.topicId) {
                const res = await getChatList({
                    current: this.current,
                    size: this.size,
                    topicId: this.$route.params.topicId,
                })
                if (res.code === 0) {
                    res.data.records = res.data.records.reverse()
                    res.data.records.forEach((item) => {
                        this.chatList.push({
                            ...item,
                            user: {
                                avatar: item.direction ? this.currentFriend.figureReq.avatar : getLocalUserInfo().avatar,
                            }
                        });
                    })

                    if(this.chatList.length > 2) {
                        this.recQuestions = [];
                        // await this.getRecQuestion({
                        //     question:this.chatList[this.chatList.length-1].content,
                        //     answer:this.chatList[this.chatList.length-2].content,
                        // })
                    }

                    this.goBottom();
                }
            }
        }
    },

}
</script>

<style scoped>

</style>
