<template>
<div class="h-full w-full flex flex-col">
    <topic-item v-for="(topic) in topicList" :topic="topic" :key="topic.id"
                @saveTopic = "saveTopic"
                @deleteTopic="deleteTopic"></topic-item>
    <div class="h-full w-full flex flex-col items-center justify-center" v-if="topicList.length === 0">
        <span class="text-xl  text-gray-400"> 暂无记录</span>
    </div>
</div>
</template>

<script>
import TopicItem from "@/views/chat/component/TopicItem.vue";
import {getTopicList, saveTopic} from "@/api/chat";
import ToastManager from "@/utils/ToastManager";

export default {
    name: "TopicList",
    components: {TopicItem},
    data(){
        return {
            topicList:[

            ],
            current:1,
            size:100,
        }
    },
    methods: {
        initData() {
            this.topicList = [];
            this.current = 1;
            this.size = 100,
            this.getTopicList();
        },
        async getTopicList() {
            const res = await getTopicList({
                current: this.current,
                size: this.size,
                friendId:this.$route.params.friendId,
            })
            if (res.code === 0) {
                res.data.records.forEach((item)=>{
                    if(item.status !== 0) {
                        this.topicList.push(item);
                    }
                })
            }
        },
        deleteTopic(topic) {
          this.$emit("deleteTopic",topic);
        },
        async saveTopic(topic) {
            const res = await saveTopic({
                id:topic.id,
                name:topic.name,
            })
            if (res.code === 0) {
                console.log("success");
                this.topicList.forEach((item)=>{
                    if (item.id === topic.id) {
                        item.name = topic.name
                        console.log("item",item);
                    }
                })
                this.$nextTick(()=>{
                    topic.callback(name);
                })
            } else {
                ToastManager.showError(res.msg);
            }
        }
    }

}
</script>

<style scoped>

</style>
