<template>
    <div class="w-full h-full flex flex-row">
        <div v-show="isShowFriend"
            class="w-full lg:w-fit h-full border-r border-solid border-slate-300 z-50">
            <friend-list/>
        </div>
        <router-view :key="key"/>
        <thinking-view />
    </div>
</template>

<script>
import FriendList from "@/views/chat/component/FriendList.vue";
import ThinkingView from "@/views/chat/component/ThinkingView.vue";
import Broadcaster from "@/utils/Broadcaster";
import {isMobile} from "@/utils/tools";


export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "index",
    components: {ThinkingView, FriendList},
    data() {
      return {
          isShowFriend:true,
      }
    },
    computed: {
        key() {
            return this.$route.path+new Date().getTime();
        }
    },
    mounted() {
        Broadcaster.$on("showFriend", (isShowFriend) => {
            this.isShowFriend = isShowFriend;
        });
        this.init();
    },

    watch: {
        $route() {
            this.init();
        }
    },


    beforeDestroy() {
      Broadcaster.$off("showFriend");
    },

    methods: {
        init() {
            if (this.$route.name === "chat") {
                if (isMobile()) {
                    this.isShowFriend = true;
                }
            } else {
                if (isMobile()) {
                    this.isShowFriend = false;
                }
            }
        }
    },
}
</script>

<style scoped>

</style>
