import Layout from "@/layout/index.vue";

const copilotRouter = {
    path: '/',
    redirect: '/objective',
    component: Layout,
    children: [

    ]
}

export default copilotRouter;
