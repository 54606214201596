<template>
    <div class="w-full flex flex-row items-center bg-white px-5 z-20" style="height: 70px !important; min-height: 70px !important;">
        <back-button class="sm:hidden" @click.native.stop="goBack"/>
        <base-button class="hidden sm:flex" :icon="isShowFriend ? mdiBackburger : mdiForwardburger " icon-size="20"
                     :tips="isShowFriend ? '收起' : '展开'"
                     @click.native.stop="showFriend"/>


        <refresh-button class="ml-5" @click.native.stop="refresh"/>
        <base-button class="ml-5" v-if="enableEditDatabase" tips="管理知识库" :icon="mdiDatabaseEditOutline" icon-size="20" @click.native.stop="goDataView"></base-button>
        <div class="flex-1"/>
        <base-button v-if="enableSaveImg && !isWorkWeMeet" tips="保存为图片" class="hidden lg:flex mr-5" :icon="mdiCameraOutline" icon-size="20" @click.native.stop="saveImg"/>
        <base-button tips="历史记录" :icon="mdiHistory" icon-size="20" @click.native.stop="showHistory"></base-button>


        <el-drawer :size="300" :visible.sync="isShowDrawer" direction="rtl" append-to-body>
            <topic-list ref="topicListRef" @deleteTopic="showDeleteDialog"/>
            <delete-dialog ref="deleteDialogRef" tips="您是否要删除该历史记录?" @submit="deleteTopic"/>
        </el-drawer>



    </div>
</template>

<script>
import BackButton from "@/components/button/BackButton.vue";
import RefreshButton from "@/components/button/RefreshButton.vue";
import {mdiBackburger, mdiForwardburger, mdiCameraOutline, mdiHistory,mdiDatabaseEditOutline} from "@mdi/js";
import BaseButton from "@/components/button/BaseButton.vue";
import Broadcaster from "@/utils/Broadcaster";
import TopicList from "@/views/chat/component/TopicList.vue";
import DeleteDialog from "@/components/dialog/DeleteDialog.vue";
import {saveTopic} from "@/api/chat";
import ToastManager from "@/utils/ToastManager";
import {isTencentMeetingApp} from "@/utils/tools";


export default {
    name: "ListHeader",
    components: {DeleteDialog, TopicList, BaseButton, RefreshButton, BackButton},
    props: {
        enableSaveImg: {
            default() {
                return false;
            }
        },
        enableEditDatabase: {
            default() {
                return false;
            }
        }
    },
    data() {
        return {
            isShowFriend: true,
            mdiBackburger: mdiBackburger,
            mdiForwardburger: mdiForwardburger,
            mdiCameraOutline:mdiCameraOutline,
            mdiDatabaseEditOutline:mdiDatabaseEditOutline,
            mdiHistory:mdiHistory,
            isShowDrawer:false,
            topicList:[],
            isWorkWeMeet:isTencentMeetingApp()
        }
    },
    methods: {
        goBack() {
            this.$router.push("/chat");
        },
        refresh() {
            Broadcaster.$emit("refreshChat", this.isShowFriend);
        },
        showFriend() {
            this.isShowFriend = !this.isShowFriend
            Broadcaster.$emit("showFriend", this.isShowFriend);
        },
        saveImg() {
            this.$emit("saveImg");
        },
        showHistory() {
            this.isShowDrawer = true;
            this.$nextTick(()=>{
                this.$refs.topicListRef.initData();
            })
        },
        goDataView() {

            if (this.$route.params.topicId) {
                this.$router.push({
                    path:`/data/chat/${this.$route.params.friendId}/${this.$route.params.topicId}`,
                })
            } else {
                this.$router.push({
                    path:`/data/chat/${this.$route.params.friendId}`,
                })
            }


        },
        async deleteTopic() {
            const res = await saveTopic({
                id:this.$route.params.topicId,
                status:0,
            })

            if (res.code === 0) {
                this.$refs.deleteDialogRef.closeDialog();
                this.$router.push({
                    path:`/chat/view/${this.$route.params.friendId}`,
                })

                Broadcaster.$emit("updateLastTopicId",{
                    topicId:this.$route.params.topicId,
                    friendId:this.$route.params.friendId,
                    type:"delete",
                })
            } else {
                ToastManager.showError(res.msg);
            }

        },
        showDeleteDialog() {
            this.$refs.deleteDialogRef.showDialog();
        }
    }
}
</script>

<style scoped>

</style>
