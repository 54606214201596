export const ChatTreeStyle = {
    spacing: {x:50,y:50},
}
export function getUrlSearchParams(key) {
    const searUrlStr = window.location.search;
    const paramStr = searUrlStr.split('?')[1];
    const searchParams = new URLSearchParams(paramStr);
    const val = searchParams.get(key);
    return val;
}
