<template>
    <div class="w-full h-full overflow-hidden pb-16 pl-0 lg:pb-0 lg:pl-16">
        <router-view/>
    </div>
</template>

<script>
export default {
    name: "MainView"
}
</script>

<style scoped>

</style>
