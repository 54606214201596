<template>
    <div class="flex flex-row w-full p-3 hover:bg-gray-200 rounded cursor-pointer">
        <base-icon :icon="icon" :icon-size="20" />
        <span class="ml-2">{{name}}</span>
    </div>
</template>

<script>
import BaseIcon from "@/components/button/BaseIcon.vue";

export default {
    name: "AddDocumentWay",
    components: {BaseIcon},
    props: {
        icon:{

        },
        name: {

        }
    }
}
</script>

<style scoped>

</style>
