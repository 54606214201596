import { render, staticRenderFns } from "./ChatTree.vue?vue&type=template&id=00a11a6b&scoped=true&"
import script from "./ChatTree.vue?vue&type=script&lang=js&"
export * from "./ChatTree.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00a11a6b",
  null
  
)

export default component.exports