<template>
    <div>
        <el-dialog :visible.sync="isShowDialog"
                   append-to-body :show-close="false" :modal="false">
            <div class="w-screen h-screen flex flex-col items-center justify-center">
                <slot name="body"/>
            </div>
        </el-dialog>
    </div>
</template>

<script>

export default {
    name: "BaseDialog",
    props: {

    },
    data() {
        return {
            isShowDialog: false,
        }
    },
    methods: {
        showDialog() {
            this.isShowDialog = true;
        },
        closeDialog() {
            this.isShowDialog = false;
        }
    },
}
</script>

<style scoped>
/deep/ .el-dialog {
    margin: 0 !important;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5) !important;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
}

/deep/ .el-dialog__header {
    padding: 0px !important;
    display: none;
}

/deep/ .el-dialog__body {
    padding: 0px !important;
}
</style>
