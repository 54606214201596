import request from "@/utils/request";

export function getChatList({current,size,topicId}) {
    return request({
        url: '/okr-copilot-service/chat/getChatList',
        method: 'get',
        params: {current,size,topicId},
    });
}

export function saveChat({content,direction,friendId,parentId,topicId,userId,status,id,sortKey}) {
    return request({
        url: '/okr-copilot-service/chat/saveChat',
        method: 'post',
        data: {content,direction,friendId,parentId,topicId,userId,status,id,sortKey},
    });
}


export function getFriendList({current,size}) {
    return request({
        url: '/okr-copilot-service/friend/getFriendList',
        method: 'get',
        params: {current,size},
    });
}

export function saveFriend({figureId,userId,status,id}) {
    return request({
        url: '/okr-copilot-service/friend/saveFriend',
        method: 'post',
        data: {figureId,userId,status,id},
    });
}

export function getFriendInfo({friendId}) {
    return request({
        url: '/okr-copilot-service/friend/getFriendInfo',
        method: 'get',
        params: {friendId},
    });
}



export function getFigureList({current,size,type}) {
    return request({
        url: '/okr-copilot-service/figure/getFigureList',
        method: 'get',
        params: {current,size,type},
    });
}


export function getTopicList({current,size,friendId}) {
    return request({
        url: '/okr-copilot-service/topic/getTopicList',
        method: 'get',
        params: {current,size,friendId},
    });
}

export function saveTopic({id,friendId,chatType,name,userId,status}) {
    const maxLen = 50
    if (name && name.length > maxLen) {
        name = name.substr(0,maxLen)
    }
    return request({
        url: '/okr-copilot-service/topic/saveTopic',
        method: 'post',
        data: {id,friendId,chatType,name,userId,status},
    });
}


