<template>
    <base-button :icon="icon" :icon-size="20" tips="开启新的对话"/>
</template>

<script>
import BaseButton from "@/components/button/BaseButton.vue";
import {mdiBrushVariant} from "@mdi/js";

export default {
    name: "RefreshButton",
    components: {BaseButton},
    data() {
        return {
            icon:mdiBrushVariant
        }
    }
}
</script>

<style scoped>

</style>
