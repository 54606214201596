<template>
    <div :class="[isSelf ? 'rounded-tl-lg bg-sky-100' : (enhance ? 'rounded-tr-lg bg-red-50' : 'rounded-tr-lg bg-red-100')]"
            class="p-4  w-9/12 max-w-fit  lg:w-5/12 lg:max-w-fit rounded-b-lg whitespace-pre-line">
      {{text}}
    </div>
</template>

<script>
export default {
    name: "ChatText",
    props: {
        text: {

        },
        isSelf: {

        },
        enhance: {
            default: false,
        },
    }
}
</script>

<style scoped>

</style>
