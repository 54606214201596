<template>
    <div class="w-full lg:w-96 h-full flex flex-col overscroll-y-none bg-gray-50">
        <div class="w-full flex flex-row items-center bg-white px-5" style="height: 70px !important; min-height: 70px">
            <div v-if="count !== null">{{ `剩余点数：${count}` }}</div>
            <div class="flex-1"/>
            <base-button :icon-size="20" :icon="mdiAccountPlusOutline" tips="添加聊天对象" @click.native="showAddDialog"/>
        </div>
        <div class="flex flex-col overflow-auto pt-2 px-2">
            <friend-item v-for="(friend,index) in friendList"
                         @select="select"
                         :user="friend" :key="friend.id"
                         :is-last="index === friendList.length-1"/>
            <div class="h-96 w-full flex flex-col items-center justify-center" v-if="friendList.length === 0">
                <span class="text-xl  text-gray-400"> 暂无聊天对象</span>
            </div>
        </div>

        <base-dialog ref="baseDialogRef">
            <div slot="body" class="w-full lg:w-5/12  h-full sm:h-4/6 lg:rounded-xl bg-white shadow-2xl overflow-hidden p-5">
                <div class="flex flex-row  w-full h-fit bg-white items-center py-5">
                    <div class="text-2xl text-black font-bold">添加聊天对象</div>
                    <div class="flex-1"/>
                    <close-button @click.native="closeAddDialog" :disabled="disabled"/>
                </div>
                <div class="flex flex-col w-full h-full pb-10 overflow-y-auto">
                    <figure-info-card class="mb-5" :figure="figure" v-for="(figure) in figureList" :key="figure.id" @submit="submit"/>
                </div>
            </div>
        </base-dialog>

    </div>
</template>

<script>
import FriendItem from "@/views/chat/component/FriendItem.vue";
import {getUserInfo} from "@/api/user";
import ToastManager from "@/utils/ToastManager";
import {getLocalUserInfo, logout, setLocalUserInfo} from "@/utils/auth";
import Broadcaster from "@/utils/Broadcaster";
import {getFigureList, getFriendList,} from "@/api/chat";
import {mdiAccountPlusOutline} from "@mdi/js";
import BaseButton from "@/components/button/BaseButton.vue";
import BaseDialog from "@/components/dialog/BaseDialog.vue";
import CloseButton from "@/components/button/CloseButton.vue";
import FigureInfoCard from "@/views/chat/component/FigureInfoCard.vue";

export default {
    name: "FriendList",
    components: {FigureInfoCard, CloseButton, BaseDialog, BaseButton, FriendItem},
    data() {
        return {
            friendList: [],
            figureList:[],
            count: null,
            current: 1,
            size: 20,
            disabled:false,
            mdiAccountPlusOutline:mdiAccountPlusOutline,
        }

    },
    mounted() {
        this.getUserInfo()
        this.update();
        Broadcaster.$on("updateBalance", () => {
            this.getUserInfo();
        })
        Broadcaster.$on("updateLastTopicId",({topicId,friendId,type})=>{
            console.log(topicId,friendId)
            this.friendList.forEach((friend)=>{
                if (friend.id.toString() === friendId.toString()) {
                    if (type === 'delete') {
                        friend.lastTopicId = null;
                    } else {
                        friend.lastTopicId = topicId;
                    }

                }
            })
        })
    },

    beforeDestroy() {
        Broadcaster.$off("updateBalance")
        Broadcaster.$off("updateLastTopicId")
    },

    methods: {

        async getUserInfo() {
            const res = await getUserInfo();
            if (res.code === 0) {
                this.count = res.data.balance;
                setLocalUserInfo(res.data);
            } else {
                ToastManager.showWarning("登陆过期，请重新登陆！");
                logout();
                this.$router.push({
                    name: "login"
                })
            }
        },

        async update() {
            const friends = await this.getFriends();
            friends.forEach((bot) => {
                this.friendList.push({
                    ...bot,
                })
            })

            if (this.friendList.length === 0) {
                ToastManager.showWarning("您还没有聊天对象，请添加！");
                this.showAddDialog();
            }
        },

        async getFriends() {
            let ans = [];
            let set = new Set();
            const res1 = await getFriendList({
                current: this.current,
                size: this.size,
            });
            if (res1.code === 0) {
                res1.data.records.forEach((friend)=>{
                    ans.push(friend);
                    set.add(friend.id);
                })
            }
            return ans;
        },

        showAddDialog() {
            this.getFigures();

        },

        closeAddDialog() {
          this.$refs.baseDialogRef.closeDialog();
        },

        submit() {
          this.closeAddDialog();
          this.friendList = [];
          this.update();
        },


        async getFigures() {
            this.figureList = [];
            const res = await getFigureList({
                current: 1,
                size: 20,
            })
            if (res.code === 0) {
                const userInfo = getLocalUserInfo();
                res.data.records.forEach((figure) => {
                    if (userInfo && userInfo.invitationCode) {
                        if (figure.invitationCode === userInfo.invitationCode || figure.isIgnoreCode) {
                            this.figureList.push(figure);
                        }
                    } else {
                        this.figureList.push(figure);
                    }

                })
                this.$refs.baseDialogRef.showDialog();
            }
        },



        select(user) {
            this.current = user;
            // this.$router.push({
            //     name: "chatView",
            //     params: {
            //         friendId: user.id.toString(),
            //     }
            // })
            if (this.current.figureReq.type === "tool" || !user.lastTopicId) {
                this.$router.push({
                    name: "chatView",
                    params: {
                        friendId: user.id.toString(),
                    }
                })
            } else {
                this.$router.push({
                    name: "chatView",
                    params: {
                        friendId: user.id.toString(),
                        topicId: user.lastTopicId.toString(),
                    }
                })
            }

        }
    }
}
</script>

<style scoped>

</style>
