<template>
    <div class="w-full h-full bg-gray-50 flex flex-col">
        <div class="m-3 lg:m-10  h-full flex flex-col bg-white rounded-xl py-3 shadow overflow-y-auto ">
            <el-table
                    :data="tableData"
                    style="width: 100%; min-width: 500px">
                <el-table-column
                        label="名称">
                    <template slot-scope="scope">
                        <div class="w-full truncate">{{scope.row.info}}</div>
                    </template>

                </el-table-column>
                <el-table-column
                        label="上传方式"
                        width="101">
                    <template slot-scope="scope">
                        <div class="w-full truncate">{{scope.row.type === 'file' ?  '文件'
                            : (scope.row.type === 'text' ?  '文本' : '网址')}}</div>
                    </template>
                </el-table-column>
                <el-table-column
                        width="101"
                        label="处理状态">
                    <template slot-scope="scope">
                        <el-tooltip :content="`已完成：${scope.row.embedding_count}，总共：${scope.row.chunk_count}`">
                            <span>{{scope.row.embedding_count === scope.row.chunk_count ? '已完成' : `处理中` }}</span>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column
                        label="操作" width="101">
                    <template slot-scope="scope">
                        <base-button color="info" :icon-size="20" :icon="mdiEyeOutline"
                                     @click.native.stop="showInfo(scope.row)" v-if="false"/>
                        <base-button color="danger" class="" :icon-size="20" :icon="mdiDeleteOutline"
                                     @click.native.stop="showDeleteDialog(scope.row)"/>
                    </template>
                </el-table-column>
            </el-table>

            <el-pagination
                    class="mt-3 ml-3"
                    background
                    layout="pager"
                    :total="pageCount"
                    @current-change="handleCurrentChange"
                    :current-page.sync="pageIndex">
            </el-pagination>
        </div>
        <delete-dialog ref="deleteDialogRef" tips="您是否确定删除当前的数据项？" @submit="deleteUploaded"/>
    </div>
</template>

<script>
import {mdiPlus, mdiDeleteOutline, mdiEyeOutline} from "@mdi/js";
import BaseButton from "@/components/button/BaseButton.vue";
import {deleteUploaded, getUploadedList} from "@/api/knowledge";
import ToastManager from "@/utils/ToastManager";
import DeleteDialog from "@/components/dialog/DeleteDialog.vue";
import Broadcaster from "@/utils/Broadcaster";

export default {
    name: "dataView",
    components: {DeleteDialog, BaseButton},
    data() {
        return {
            mdiPlus: mdiPlus,
            mdiDeleteOutline: mdiDeleteOutline,
            mdiEyeOutline: mdiEyeOutline,
            tableData: [],
            pageCount: 0,
            pageIndex: 1,
            pageSize: 10,
            deleteItem: null
        }
    },
    mounted() {
        this.getDataList();
        Broadcaster.$on("updateUploadedList",()=>{
            this.getDataList()
        })
    },
    beforeDestroy() {
        Broadcaster.$off("updateUploadedList")
    },
    methods: {
        handleCurrentChange() {
            //console.log(val,this.pageIndex)
            this.getDataList()
        },
        showDeleteDialog(item) {
            if (item.chunk_count === item.embedding_count) {
                this.deleteItem = item
                this.$refs.deleteDialogRef.showDialog();
            } else {
                ToastManager.showWarning("处理中的数据无法删除，请稍后重试！")
            }

        },
        showInfo() {

        },

        async deleteUploaded() {
          this.$refs.deleteDialogRef.closeDialog();
          const res = await deleteUploaded({
              docId:this.deleteItem.id,
              friendId:this.$route.params.friendId,
          })
            if (res.code === 0) {
                ToastManager.showSuccess("删除成功！");
                await this.getDataList();
            } else {
                ToastManager.showError(res.msg);
            }
        },

        async getDataList() {
            const res = await getUploadedList({
                friendId: this.$route.params.friendId,
                pageSize: this.pageSize,
                pageIndex: this.pageIndex,
            })
            if (res.code === 0) {
                this.tableData = res.data;
                this.pageCount = res.page_count;
            } else {
                ToastManager.showError(res.msg);
            }
        }
    }
}
</script>

<style scoped>
/deep/ .el-pager li.active {
    background-color: #2563EB !important;
}
</style>
