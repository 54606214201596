<template>
  <div class="h-full w-full">
      <app-bar />
      <main-view/>
  </div>
</template>

<script>
import AppBar from "@/layout/component/AppBar.vue";
import MainView from "@/layout/component/MainView.vue";

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "index",
    components: {MainView, AppBar}
}
</script>

<style scoped>

</style>
