import request from "@/utils/request";

export function getSmsCode({phone}) {
    return request({
        url: '/okr-copilot-user-service/user/getSmsCode',
        method: 'get',
        params: {phone},
    });
}

export function forgetPassword({phoneNumber,smsCode,smsToken,newPassword}) {
    return request({
        url: '/okr-copilot-user-service/user/forgetPassword',
        method: 'post',
        params: {phoneNumber,smsCode,smsToken,newPassword,},
    });
}


export function login({phoneNumber,smsCode,smsToken,loginType,password,invitationCode}) {
    return request({
        url: '/okr-copilot-user-service/user/login',
        method: 'post',
        params: {phoneNumber,smsCode,smsToken,loginType,password,invitationCode},
    });
}

export function getUserInfo() {
    return request({
        url:'/okr-copilot-user-service/user/getUserInfo',
        method:'get',
    })
}

export function setUserInfo({id,avatar,name}) {
    return request({
        url: '/okr-copilot-user-service/user/setUserInfo',
        method: 'post',
        params: {id,avatar,name},
    });
}
